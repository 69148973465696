import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/urlConfig";

const AddDrug = ({ setShowAD, setDrug, handleSaveDrug }) => {
  const [localDrug, setLocalDrug] = useState({
    drug_id: "",
    dose: "",
    drug_freq_id: "",
    comments: "",
    duration: "",
    period: "",
    notes: "",
  });
  const [drugList, setDrugList] = useState([]);
  const [filteredDrugs, setFilteredDrugs] = useState([]);
  const [drugFrequencies, setDrugFrequencies] = useState([]); // Store drug frequencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        const drugResponse = await axios.get(`${BASE_URL}/api/drug-details`);
        setDrugList(drugResponse.data.drugDetails);

        const freqResponse = await axios.get(`${BASE_URL}/api/drug-frequencies`);
        setDrugFrequencies(freqResponse.data.drugFrequencies); 
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalDrug((prev) => ({ ...prev, [name]: value }));

    if (name === "drug_id") {
      if (value.trim() === "") {
        setFilteredDrugs([]);
      } else {
        const suggestions = drugList.filter((drug) =>
          drug.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredDrugs(suggestions);
      }
    }
  };

  const handleSelectDrug = (drug) => {
    setLocalDrug((prev) => ({
      ...prev,
      drug_id: drug.name,
      period: drug.type_name,
    }));
    setFilteredDrugs([]);
  };

  const handleSave = (e) => {
    e.preventDefault();
    handleSaveDrug();
    setShowAD(false);
    setDrug(localDrug);
    console.log(localDrug);
  };

  const handleCancel = () => {
    setShowAD(false);
  };

  return (
    <div
      className="absolute block top-24 ml-300px bg-[#ECECEC] mx-auto rounded-lg overflow-hidden p-4"
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <button className="text-red-500" onClick={handleCancel}>
          Close
        </button>
      </div>
      <form>
        <div className="flex items-center justify-between">
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="drug_id"
              placeholder="Type Drug Name"
              className="w-[200px] mt-1 p-2 text-sm border rounded-md"
              value={localDrug.drug_id}
              onChange={handleInputChange}
            />
            {filteredDrugs.length > 0 && (
              <div className="absolute z-10 w-[200px] bg-white border mt-1 shadow-md">
                {filteredDrugs.map((drug) => (
                  <div
                    key={drug.id}
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleSelectDrug(drug)}
                  >
                    {drug.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Type</label>
            <input
              type="text"
              name="period"
              className="w-[100px] mt-1 p-2 border"
              value={localDrug.period}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="mb-4">
            <label className="block text-gray-700">Dose</label>
            <select
              name="dose"
              className="w-full mt-1 p-2 border"
              value={localDrug.dose}
              onChange={handleInputChange}
            >
              <option value="">Select Dose</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Frequency</label>
            <select
              name="drug_freq_id"
              className="w-full mt-1 p-2 border"
              value={localDrug.drug_freq_id} // Store ID here
              onChange={handleInputChange}
            >
              <option value="">Select Frequency</option>
              {drugFrequencies.map((freq) => (
                <option key={freq.id} value={freq.id}> {/* Store the ID here */}
                  {freq.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Comments</label>
            <input
              name="comments"
              type="text"
              placeholder="Take it regularly"
              className="w-full mt-1 p-2 border rounded-md"
              value={localDrug.comments}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="mb-4">
            <label className="block text-gray-700">Duration</label>
            <select
              name="duration"
              className="w-full mt-1 p-2 border rounded-md"
              value={localDrug.duration}
              onChange={handleInputChange}
            >
              <option value="">Select Duration</option>
              <option value="10">10 Weeks</option>
              <option value="20">20 Weeks</option>
              <option value="30">30 Weeks</option>
              <option value="40">40 Weeks</option>
              <option value="50">50 Weeks</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Notes</label>
            <textarea
              name="notes"
              placeholder="Mention specific instructions"
              className="w-full mt-1 p-2 border rounded-md"
              value={localDrug.notes}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDrug;
