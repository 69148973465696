import React, { useEffect, useState } from "react";
import pic from "../images/Rectangle 2441.svg";
import CrossIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import axios from "axios";
const CpProfile = ({ setShowProfile }) => {
  const [pic, setPic] = useState();
  const [user, setUser] = useState([]);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        const data = res.data;

        const calculateAge = (dob) => {
          const [day, month, year] = dob.split("/").map(Number);
          const birthDate = new Date(year, month - 1, day);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        };

        setUser({
          ...data,
          age: calculateAge(data.dob),
        });

        setPic(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/cp/${user.id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user.id, token]);

  const handleClose = () => {
    setShowProfile(false);
  };
  return (
    <div
      className="absolute block top-24 ml-300px bg-[#ECECEC] mx-auto rounded-lg overflow-hidden"
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="flex rounded-sm">
        <div>
          <img src={pic} alt="" />
        </div>
        <div className="bg-[#0E044A] text-white p-4 ">
          <div className="text-[#D5CDFFC2]">PROFILE</div>
          <div>
            {user.first_name} {user.middle_name} {user.last_name}
          </div>
          <div>{user.degree}</div>

          <div className="mb-5">
            {user?.address?.address_line1 && (
              <>
                {user.address.address_line1}
                {user.address.city_block}
                {user.address.district}
                {user.address.state}
              </>
            )}
          </div>

          <div className="text-[#D5CDFFC2] mb-3">SPECIALITY</div>
          <div className="flex mb-5 justify-between">
            <div className="border rounded-sm p-2 text-xs">
              {user.specialty}
            </div>
            <div className="border rounded-sm p-2 text-xs">
              {user.sub_specialty}
            </div>
            <div className="border rounded-sm p-2 text-xs">
              {user.super_specialty}
            </div>
          </div>

          <div className="text-[#D5CDFFC2]">EXPERIENCE</div>
          <div className="mb-5">20+ YEARS</div>

          <div className="text-[#D5CDFFC2] mb-3">AVAILABILITY</div>
          <div className="grid grid-cols-3 gap-4">
            <div className="border rounded-sm p-2 text-xs">Today, 2 PM</div>
            <div className="border rounded-sm p-2 text-xs">Today, 2 PM</div>
            <div className="border rounded-sm p-2 text-xs">Today, 2 PM</div>
            <div className="border rounded-sm p-2 text-xs">Today, 2 PM</div>
            <div className="border rounded-sm p-2 text-xs">Today, 2 PM</div>
            <div className="border rounded-sm p-2 text-xs">Today, 2 PM</div>
          </div>
          <CrossIcon
            className="absolute top-8 right-5 cursor-pointer"
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

export default CpProfile;
