import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "./pages/admin/Dashboard/HomePage";

import EmployeeDetails from "./pages/admin/AddEmployee/EmployeeDetails";
import CPForm from "./pages/admin/AddEmployee/EmployeeAddPages/CPForm";
import CFCPForm from "./pages/admin/AddEmployee/EmployeeAddPages/CFCPForm";
import EclinicForm from "./pages/admin/AddEmployee/EmployeeAddPages/EclinicForm";
import THProviderForm from "./pages/admin/AddEmployee/EmployeeAddPages/THProviderForm";
import EditCP from "./pages/admin/AddEmployee/EmployeeEditPages/EditCP";
import EditCFCP from "./pages/admin/AddEmployee/EmployeeEditPages/EditCFCP";
import EditCFeclinic from "./pages/admin/AddEmployee/EmployeeEditPages/EditCFeclinic";
import EditTHProvider from "./pages/admin/AddEmployee/EmployeeEditPages/EditTHProvider";

import StoreDetails from "./pages/admin/AddStore/StoreDetails";
import AddNPH from "./pages/admin/AddStore/StoreAddPages/AddNPH";
import AddNDH from "./pages/admin/AddStore/StoreAddPages/AddNDH";
import AddEclinic from "./pages/admin/AddStore/StoreAddPages/AddEclinic";
import EditNPH from "./pages/admin/AddStore/StoreEditPages/EditNPH";
import EditNDH from "./pages/admin/AddStore/StoreEditPages/EditNDH";
import EditEclinic from "./pages/admin/AddStore/StoreEditPages/EditEclinic";

import axios from "axios";
import DrugDetails from "./pages/admin/AddDrugs/DrugDetails";
import LabTestsDetails from "./pages/admin/AddLabTests/LabTestDetails";
import ZoneDetails from "./pages/admin/AddZones/ZoneDetails";
import ForgetPasswordForm from "./components/LoginForm/ForgetPasswordForm ";

import PrivateRoute from "./components/PrivateRoute";

//importing cfeclinic
import Dashboard from "./components/cf_e_clinic/dashboard/Dashboard";
import MyConsultation from "./components/cf_e_clinic/myconsultation/MyConsultation";
import MyOrder from "./components/cf_e_clinic/myorders/MyOrders";
import PatientDashboard from "./components/cf_e_clinic/patientdashboard/PatientDashboard";
import SidebarEClinic from "./components/cf_e_clinic/layout/SidebarEClinic";
import Room from "./components/cf_e_clinic/myconsultation/Room";
import LoginForm from "./components/LoginForm/LoginForm";
// cf-ap
import Sidebar from "./components/cfcpdashboard/layout/Sidebar";
import Cf_Cp_Dashboard from "./components/cfcpdashboard/cfcp_dashboard/Cf_Cp_Dashboard";
import ConsultationQueue from "./components/cfcpdashboard/cfcpconsultationqueue/ConsultationQueue";
import MyCalendar from "./components/cfcpdashboard/cfcp_DoctorsRoster/MyCalendar";
// NDH
import NDHSidebar from "./components/NDH/NDHLayout/NDHSidebar";
import NDHDeshbboard from "./components/NDH/NDHDeshboard/NDHDeshbboard";
import NDHOrder from "./components/NDH/NDHOrder/NDHOrder";
// cp
import SidebarCp from "./components/cp/Layout/SidebarCp";
import CpDashboard from "./components/cp/Dashboard/CpDashboard";
import ApprovedConsultation from "./components/cp/Approved_consultation/ApprovedConsultation";
import AllConsultation from "./components/cp/All_consultation/AllConsultation";
import CpRoom from "./components/cp/Approved_consultation/CpRoom";
import Addprescription from "./components/cp/Approved_consultation/Addprescription";
import VideoCallLayout from "./components/cp/Approved_consultation/VideoCallLayout";
// axios.defaults.baseURL = "http://13.203.127.220:8080/";
axios.defaults.baseURL = "https://api.chaoticchacha.in/";

const App = () => {
  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} />

      <Router>
        <Routes>
          {/* Public route for the login page */}

          <Route path="/" element={<LoginPage />}>
            <Route index element={<Navigate to="login" replace />} />
            <Route path="login" element={<LoginForm />} />{" "}
            <Route path="forgotpassword" element={<ForgetPasswordForm />} />
          </Route>

          {/* Admin */}
          <Route path="/" element={<PrivateRoute roles={[1]} />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/EmployeeDetails" element={<EmployeeDetails />} />
            <Route path="/addCP" element={<CPForm />} />
            <Route path="/addCFCP" element={<CFCPForm />} />
            <Route path="/addCFEclinic" element={<EclinicForm />} />
            <Route path="/addThProvider" element={<THProviderForm />} />

            <Route path="employee/cp/:employeeId" element={<EditCP />} />
            <Route path="employee/cfcp/:employeeId" element={<EditCFCP />} />
            <Route
              path="employee/cfeclinic/:employeeId"
              element={<EditCFeclinic />}
            />
            <Route
              path="employee/telehealthproviders/:employeeId"
              element={<EditTHProvider />}
            />

            <Route path="/StoreDetails" element={<StoreDetails />} />
            <Route path="/AddNPH" element={<AddNPH />} />
            <Route path="/AddNDH" element={<AddNDH />} />
            <Route path="/AddEclinic" element={<AddEclinic />} />
            <Route path="store/nph/:storeId" element={<EditNPH />} />
            <Route path="store/ndh/:storeId" element={<EditNDH />} />
            <Route path="store/eclinic/:storeId" element={<EditEclinic />} />

            <Route path="/DrugDetails" element={<DrugDetails />} />
            <Route path="/LabTestsDetails" element={<LabTestsDetails />} />
            <Route path="/ZoneDetails" element={<ZoneDetails />} />
          </Route>

          {/* cfeclinic */}
          <Route path="/" element={<PrivateRoute roles={[4]} />}>
            <Route path="/" element={<SidebarEClinic />}>
              <Route index path="dasheclinic" element={<Dashboard />} />
              <Route path="consulteclinic" element={<MyConsultation />} />
              <Route path="myordereclinic" element={<MyOrder />} />
              <Route path="patientdasheclinic" element={<PatientDashboard />} />
              <Route
                path="consulteclinic/room/:roomID/:name"
                element={<Room />}
              />
              </Route>
          </Route>
          {/* cfcp */}
          <Route path="/" element={<PrivateRoute roles={[3]} />}>
            <Route path="/" element={<Sidebar />}>
              <Route path="cf_Cp_Dashboard" element={<Cf_Cp_Dashboard />} />
              <Route path="ConsultationQueue" element={<ConsultationQueue />} />
              <Route path="MyCalendar" element={<MyCalendar />} />
            </Route>
          </Route>

          {/* NDH */}
          <Route path="/" element={<PrivateRoute roles={[8]} />}>
          <Route path="/" element={<NDHSidebar />}>
            <Route path="NDHDeshbboard" element={<NDHDeshbboard />} />
            <Route path="NDHOrder" element={<NDHOrder />} />
          </Route>
          </Route>


          {/* cp */}


          {/* <Route path="/" element={<PrivateRoute roles={[2]} />}> */}
          <Route path="/" element={<SidebarCp />}>
            <Route path="CpDashboard" element={<CpDashboard />} />
            <Route path="Approved-Consultation" element={<ApprovedConsultation />} />
            <Route path="All-Consultation" element={<AllConsultation />} />
            <Route path="Addprescription" element={<Addprescription />} />

            <Route path="consultCp/room/:roomID/:name/:consultant/:patinet" element={<VideoCallLayout />} />

          </Route>
          {/* </Route> */}
        </Routes>
      </Router>
    </>
  );
};

export default App;
