import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CpRoom from "./CpRoom";
import Addprescription from "./Addprescription";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Avtar from '../images/Avtar (1).svg'

const VideoCallLayout = () => {
  const { roomID, name, consultantid } = useParams();
  const [isMinimized, setIsMinimized] = useState(false);
  const [position, setPosition] = useState({ x: window.innerWidth - 160, y: window.innerHeight - 120 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div className="flex h-full w-full">
      {/* Video Call Section */}
      {isMinimized ? (
        <div
          className="fixed z-50 rounded-lg overflow-hidden flex items-center justify-center bg-[#1C1F2E] cursor-grab"
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
            width: "160px",
            height: "120px",
          }}
          onMouseDown={handleMouseDown}
        >
          <CpRoom roomID={roomID} name={name} isMinimized={isMinimized} />
          <div className=" absolute h-120 w-160 bg-[#1C1F2E] w-full h-full flex items-center justify-center">
              <img src={Avtar} alt="Avatar" className="w-10 h-6" />
          <button
            onClick={toggleMinimize}
            className="absolute bg-[#3A3D4A] p-2 rounded-lg z-40 text-white top-2 right-2 "
          >
            <FullscreenIcon />
          </button>

          </div>
        </div>
      ) : (
        <div className="absolute top-[85px] bottom-0 right-0 left-0 z-30 bg-[#1C1F2E]">
          <CpRoom roomID={roomID} name={name} isMinimized={isMinimized} />
          <button
            onClick={toggleMinimize}
            className="absolute bg-[#3A3D4A] p-2 rounded-lg z-40 text-white top-2 right-2 ">

            <FullscreenExitIcon />
          </button>
        </div>
      )}

      <div className="h-full bg-gray-100 p-4">
        <Addprescription consultantid={consultantid} isMinimized={isMinimized} />
      </div>
    </div>
  );
};

export default VideoCallLayout;
