import React, { useEffect, useState } from "react";
import "./Prescription.css";
import Logo from "../images/Logo.svg";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useSelector } from "react-redux";

const Prescription = ({ setShowrescription, consultantid }) => {
  const [prescriptionDetails, setPrescriptionDetails] = useState({
    medications: [],
  });
  const [cp, setCp] = useState([]);
  const[patient, setPatient]=useState([]);
  const handleDownloadPDF = async () => {
    const content = document.querySelector(".prescription-container");

    if (content) {
      const canvas = await html2canvas(content, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 190;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

      pdf.save("prescription.pdf");
    }
  };
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      try {
        // prescription
        console.log(consultantid);
        const res = await axios.get(`/api/prescription/${consultantid}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log(res.data);
        setPrescriptionDetails(res.data.prescriptionDetails);
        const rescp = await axios.get(
          `/api/cp/${res.data.prescriptionDetails.cp_id}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setCp(rescp.data);

        const ptnt = await axios.get(`/api/patient/${res.data.prescriptionDetails.patient_id}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )

        setPatient(ptnt.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    console.log("Close button clicked!");
    setShowrescription(false);
  };
  return (
    <>
      <div className="prescription-body absolute block top-10">
        <div className="prescription-container">
          <div className="prescription-header">
            <div className="prescription-logo">
              <img src={Logo} alt="Company Logo" />
            </div>
            <div className="prescription-title">
              <h4>PRESCRIPTION</h4>
            </div>
          </div>
          <div className="prescription-patient-details">
            <div>
              <p>
                <span>PID:</span>
                {prescriptionDetails.prescription_id}
              </p>
              <p>
                <span>Consultation ID:</span>
                {prescriptionDetails.consultation_id}
              </p>
              <p>
                <span>EC-ID:</span>
                {prescriptionDetails.patient_id}
              </p>
            </div>
            <div>
              <p className="prescription-pname">
                <span>Name:</span>
                {patient.name}
              </p>
              <p>
                <span>Age / Sex:</span>
                {patient.gender}
              </p>
              <p>
                <span>Mob:</span>
                {patient.mobile}
              </p>
            </div>

            <div>
              {/* <p>
                <span>Address:</span> {patient.address.address_line1}{" "}
                {patient.address.address_line2} {patient.address.city_block}{" "}
                {patient.address.state} {patient.address.district}{" "}
                {patient.address.zipcode}{" "}
              </p> */}
            </div>
            <div>
              <p>
                <span>Consultant ID:</span> {prescriptionDetails.patient_id}
              </p>
            </div>
          </div>
          <div className="prescription-section1">
            <h3>Observations:</h3>
            <div className="prescription-box">
              {prescriptionDetails.observation}
            </div>
          </div>
          <div className="prescription-advice">
            <p>Advised Investigations:</p>
            <ul className="flex">
              {Array.isArray(prescriptionDetails.labTests) &&
                prescriptionDetails.labTests.map((test, index) => (
                  <li key={test.lab_test_prescription_id} className="ml-1">
                    {index + 1}. {test.lab_test_name}
                  </li>
                ))}
            </ul>
          </div>

          <div className="prescription-section2">
            <h3>Medicines:</h3>
            <div className="prescription-medicine">
              <table>
                <thead>
                  <tr>
                    <th>Rx</th>
                    <th>Name</th>
                    <th>Frequency</th>
                    <th>Duration</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(prescriptionDetails.medications) &&
                    prescriptionDetails.medications.map((medication, index) => (
                      <tr key={medication.med_prescription_id}>
                        <td>{index + 1}</td>
                        <td>{medication.drug_name}</td>
                        <td>{medication.dose}</td>
                        <td>{medication.frequency_name}</td>
                        <td>{medication.comments}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="prescription-footer">
            <p>
              <span>Follow Up Date:</span>{" "}
              {prescriptionDetails?.followup_date
  ? new Date(prescriptionDetails.followup_date).toISOString().split("T")[0]
  : "no follow-up date"}            </p>
            <p>
              <span>Signature: </span>
            </p>
            <p>
              <span>Consultant Name: </span> {cp.first_name} {cp.middle_name}{" "}
              {cp.last_name}
            </p>
            <p>
              <span>Details:</span> {cp.specialty}, {cp.sub_specialty},{" "}
              {cp.super_specialty}
            </p>
          </div>
        </div>
        <div className="prescription-buttons">
          <button onClick={handleClose} className="btn-close">
            Close
          </button>
          <button onClick={handleDownloadPDF} className="btn-download">
            Download
          </button>
        </div>
      </div>
    </>
  );
};

export default Prescription;
