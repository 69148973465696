import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import Prescription from "../../NDH/NDHOrder/Prescription";
import OrderStatus from "./OrderStatus";
const AllOreders = ({ handleInfo }) => {
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [showprescription, setShowrescription] = useState(false);
  const [consultantid, setConsultantid] = useState();
  const [showstatus, setShowstatus] = useState(false);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/medicineAcceptedByNdh`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        // Format the requested_date field
        const formattedData = res.data.medicineResults.map((medicine) => ({
          ...medicine,
          created_at: medicine.created_at.split("T")[0],
        }));

        console.log(formattedData);
        setAllOrdersData(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  const handlprescription = (id) => {
    setShowrescription(true);
    setConsultantid(id);
  };

  const handlechangestatus = async (id, currentStatus) => {
    try {
      let newStatus =
        currentStatus === 2 ? 3 : currentStatus === 3 ? 4 : currentStatus;

      if (newStatus === currentStatus) return; // Prevent unnecessary updates

      const response = await axios.put(
        `${BASE_URL}/api/updateMedicineStatus`,
        {
          orderNo: id,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      console.log("Response data:", response.data);
    } catch (err) {
      console.error("Error updating status:", err);
    }
  };
  const [status, setStatus] = useState();
  const [cid, setCid] = useState();

  const handelStatus = ({ status, id }) => {
    setShowstatus(true);
    console.log(status);
    console.log(id);

    setStatus(status);
    setCid(id);
  };

  const [showStatus, setShowStatus] = useState(false);
  const [statusInfo, setStatusInfo] = useState({ status: null, id: null });
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const tableRef = useRef(null);
  const handleStatus = (row, event) => {
    if (showStatus && statusInfo.id === row.consultation_id) {
      setShowStatus(false); // Close popup if the same row is clicked
      return;
    }

    // Get the button's position relative to the viewport
    const rect = event.target.getBoundingClientRect();

    // Calculate popup position based on button's position
    setPosition({
      top: rect.top + window.scrollY + rect.height + 5 - 230, // 5px below the button
      left: rect.left + window.scrollX - 150 - 450, // Adjust left to center the popup
    });
    console.log(position);

    setStatusInfo({ status: row.status, id: row.consultation_id });
    setShowStatus(true);
  };

  return (
    <div className="relative">
      <div className="mt-4 bg-white p-4 max-w-screen-xl mx-auto rounded-lg shadow-lg">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
            <div className=" sm:mr-auto flex gap-5">
              <span>
                <p className="ml-9">Search</p>

                <div>
                  <SearchIcon
                    className="relative left-9 "
                    style={{ fontSize: "30px", color: "gray" }}
                  />
                  <input
                    type="text"
                    placeholder="Search by order no"
                    className="p-1 pl-9 rounded-full 420px:w-[50vh] border-2 border-gray-400 320px:w-[40vh]"
                  />
                </div>
              </span>
              <span>
                <p>Sort by</p>
                <input
                  type="text"
                  placeholder="PendingOreders"
                  className="p-1 pl-9 rounded-full 420px:w-[50vh] text-center border-2 border-gray-400 320px:w-[40vh]"
                />
              </span>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="min-w-full">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 text-center">Order No</th>
                    <th className="px-4 py-2 text-center">Request Date</th>
                    <th className="px-4 py-2 text-center">Consultation ID</th>
                    <th className="px-4 py-2 text-center">Drug Requested</th>
                    <th className="px-4 py-2 text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrdersData && allOrdersData.length > 0 ? (
                    allOrdersData.map((row, index) => (
                      <tr key={index} className="border-b hover:bg-gray-50">
                        <td className="border p-2 text-center">
                          {row.order_id}
                        </td>
                        <td
                          className={`border p-2 text-center`}
                          onClick={handleInfo}
                        >
                          {row.created_at}
                        </td>
                        <td className="border p-2 text-center">
                          {row.consultation_id}
                        </td>
                        <td className="px-4 py-2 text-center">
                          <DescriptionIcon
                            className="mr-2 cursor-pointer"
                            onClick={() =>
                              handlprescription(row.consultation_id)
                            }
                          />
                        </td>
                        <td className="border p-2 text-center">
                          <div className="flex items-center">
                            <button
                              className={`border-2 px-[3px] w-[80px] rounded-sm block mx-auto ${
                                row.status === 2
                                  ? "text-gray-500 border-gray-500"
                                  : row.status === 3
                                  ? "text-blue-500 border-blue-500"
                                  : "text-yellow-500 border-yellow-500"
                              }`}
                              onClick={() =>
                                handlechangestatus(row.order_id, row.status)
                              }
                            >
                              {row.status === 2
                                ? "Ready"
                                : row.status === 3
                                ? "Shipped"
                                : "Delivered"}
                            </button>
                            {/* <InfoIcon
                              className="cursor-pointer"
                              onClick={()=>handelStatus({status:row.status, id:row.consultation_id})}
                            /> */}

                            {/* <button
                              className="px-2 py-1 bg-blue-500 text-white rounded"
                              onClick={(event) => handleStatus(row, event)}
                            > */}
                              <InfoIcon className="cursor-pointer" onClick={(event) => handleStatus(row, event)}/>
                            {/* </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center p-4">
                        No orders available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} className="flex items-end mt-4">
        <Pagination
          // count={Math.ceil(filteredPatients.length / patientsPerPage)}
          // page={page}
          // onChange={handleChangePage}
          color="primary"
        />
      </Stack>

      {showStatus && (
        <OrderStatus
          setShowstatus={setShowStatus}
          status={statusInfo.status}
          cid={statusInfo.id}
          position={position}
        />
      )}

      {showprescription && (
        <div>
          <Prescription
            setShowrescription={setShowrescription}
            consultantid={consultantid}
          />
        </div>
      )}
    </div>
  );
};

export default AllOreders;
