import React, { useState, useEffect } from 'react';
import NewRequest from './NewRequest';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import axios from "axios";
const ConsultationDetails = () => {
  const [showNewReq, setShowNewReq] = useState(false);
  const [consultantid, setConsultantid]=useState()
  const handleNewReq = () => {
    setShowNewReq(true);
  };

  const [consultants, setConsultants] = useState([]);
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/consultation/cp`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log(res.data);
        const filteredConsultants = res.data.consultations.filter(
          ({ status }) => status === -1 || status === 0
        );

        setConsultants(filteredConsultants);
      } catch (error) {
        console.error("Error fetching consultations:", error);
      }
    };
    fetchConsultations();
  }, [token]);

  return (
    <>
    <div className={`mt-12 ${showNewReq ? "blur-sm" : ""}`}>
      <table className="w-full border-collapse border border-gray-400 text-sm text-left">
        <thead className="min-w-full border-collapse">
          <tr className="bg-[#F8F8F8]">
            <th className="border text-center border-gray-400 px-4 py-2">Patient ID</th>
            <th className="border text-center border-gray-400 px-4 py-2">Consultation ID</th>
            <th className="border text-center border-gray-400 px-4 py-2">Patient Name</th>
            <th className="border text-center border-gray-400 px-4 py-2">Consultation Type</th>
            <th className="border text-center border-gray-400 px-4 py-2">Consultation Date</th>
            <th className="border text-center border-gray-400 px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {consultants.length > 0 ? (
            consultants.map((consultant, index) => (
              <tr key={index} className="border-gray-400">
                  <td className="border text-center px-4 py-2">
                    {consultant.patient_id || "N/A"}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {consultant.id || "N/A"}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {consultant.patient_name || "N/A"}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {consultant.type === 1 ? "Scheduled" : "instant" || "N/A"}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {consultant.meeting_meta.start_time || "N/A"}
                  </td>
                  <td className="border text-center px-4 py-2 ">
                    <p
                      className={`border-2 px-[3px] w-[80px] rounded-sm block mx-auto text-yellow-500 border-yellow-500`}
                      onClick={() => {
                        setConsultantid(consultant.id);
                        setShowNewReq(true);
                      }}
                    >
                      Requested
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="text-center text-gray-500 px-4 py-2"
                >
                  No consultations found with status -1 or 0.
                </td>
              </tr>
            )}
        </tbody>
      </table>

      <Stack spacing={2} className="flex items-end mt-4">
          <Pagination
            // count={Math.ceil(filteredPatients.length / patientsPerPage)}
            // page={page}
            // onChange={handleChangePage}
            color="primary"
          />
        </Stack>

      </div>
      {showNewReq && (
        <div>
          <NewRequest id={consultantid} />
        </div>
      )}
      </>
  );
};

export default ConsultationDetails;
