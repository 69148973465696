import React, { useState, useCallback, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import avatar from "../images/Avtar (1).svg";
import DescriptionIcon from "@mui/icons-material/Description";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"; // <-- import Link for navigation
import axios from "axios";
import Existing from "../dashboard/Existing";
import ExistingPatientList from "../dashboard/ExistingPatientList";
import EditPatient from "../dashboard/EditPatient";
import Newconsultation from "../dashboard/Newconsultation";
import ConsultationCompleted from "./ConsultationCompleted";
import EditNewconsultation from "./EditNewconsultation";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase";
const MyConsultation = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  
  const [showexisting, setShowexisting] = useState(false);
  const [showExistingPatientList, setShowExistingPatientList] = useState(false);
  const [existingPatients, setExistingPatients] = useState([]);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [editPatientid, setEditPatientid] = useState();
  const [showConsultation, setShowConsultation] = useState(false);
  const [consultationid, setConsultationid] = useState();
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const [showConsultationCompleted, setShowConsultationCompleted] =
    useState(false);
  const [showeditc, setShoweditc] = useState(false);

  //video call
  const [value, setValue] = useState();
  const [name, setName] = useState();
  const navigate = useNavigate();
  const handleJoinRoom = useCallback(
    (name, value) => {
      setValue(value);
      setName(name);
      navigate(`room/${value}/${name}`);
      console.log("Navigating to room");
    },
    [navigate, value]
  );

  const [consultants, setConsultants] = useState([]);
  const today = new Date();
  const initialFormattedDate = today.toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);
  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Convert the selected date to local time and format it as "yyyy-mm-dd"
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formatted = `${localDate.getFullYear()}-${String(
      localDate.getMonth() + 1
    ).padStart(2, "0")}-${String(localDate.getDate()).padStart(2, "0")}`;
    setFormattedDate(formatted);

    console.log("Formatted Date (after selection):", formatted);
  };







  ///////////////////firebase///////////////////////

  const [agentid, setAgentid] = useState(null);
  const [status, setStatus] = useState([]);
  
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setAgentid(res.data.user_id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);
  
  useEffect(() => {
    if (agentid) {

      console.log(agentid)
      
    const q = query(
      collection(db, "update_consultation_status"),
      where("agentId", "==", agentid)
    );
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Filtered Data:", newData);
      setStatus(newData);
    });
    
    return () => unsubscribe(); 
  }
  }, [agentid]); 
  
  //////////////////////firebase/////////////////////////
  const [statusCounts, setStatusCounts] = useState({
    declined: 0,
    requested: 0,
    approved: 0,
    completed: 0,
    closed: 0,
  });

  // Function to calculate and set status counts
  const calculateStatusCounts = useCallback((consultants) => {
    const counts = {
      declined: 0,
      requested: 0,
      approved: 0,
      completed: 0,
      closed: 0,
    };
    consultants.forEach(({ status }) => {
      switch (status) {
        case -1:
          counts.declined++;
          break;
        case 0:
          counts.requested++;
          break;
        case 1:
          counts.approved++;
          break;
        case 2:
          counts.completed++;
          break;
        case 3:
          counts.closed++;
          break;
        default:
          break;
      }
    });
    setStatusCounts(counts);
  }, []);

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/consultations?date=${formattedDate}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log("xxxxxxxxxxxxxxxxxxxxx", formattedDate);
        setConsultants(res.data.consultations);
        calculateStatusCounts(res.data.consultations);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching consultations:", error);
      }
    };
    fetchConsultations();
  }, [formattedDate, calculateStatusCounts]);

  // Button to open document link
  const [docWindow, setDocWindow] = useState(null);

  const handleViewAllDocuments = (documents) => {
    const prefix = `${BASE_URL}/api/pdf`;
    const docUrls = documents.split(",").map((url) => `${prefix}${url.trim()}`);

    // Open a new window if it's not already open
    let newWindow = docWindow;
    if (!newWindow || newWindow.closed) {
      newWindow = window.open("", "_blank", "width=800,height=600");
      newWindow.document.write(
        "<html><head><title>Consultation Documents</title></head><body></body></html>"
      );
      setDocWindow(newWindow);
    }

    docUrls.forEach((url) => {
      const iframe = newWindow.document.createElement("iframe");
      iframe.src = url;
      iframe.width = "100%";
      iframe.height = "500px";
      iframe.style.border = "none";
      newWindow.document.body.appendChild(iframe);
      newWindow.document.body.appendChild(document.createElement("br"));
    });
  };

  const [patients, setPatients] = useState({});

  const fetchPatient = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/api/patient/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      const updatedPatient = {
        ...res.data,
        documentUrl: res.data.photo
          ? `${BASE_URL}/api/image/${
              res.data.photo.startsWith("/")
                ? res.data.photo.slice(1)
                : res.data.photo
            }`
          : null,
      };

      setPatients((prev) => ({ ...prev, [id]: updatedPatient }));
      // console.log(patients);
    } catch (error) {
      console.error("Error fetching patient details:");
    }
  };

  useEffect(() => {
    consultants.forEach((consultant) => {
      if (consultant.patient_id && !patients[consultant.patient_id]) {
        fetchPatient(consultant.patient_id);
      }
    });
  }, [consultants]);

  const [cps, setCps] = useState([]);
  const fetchCp = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/api/cp/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const dob = new Date(res.data.dob);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();

      const monthDifference = today.getMonth() - dob.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < dob.getDate())
      ) {
        age--;
      }

      const updatedCp = {
        ...res.data,
        age: age,
      };

      setCps((prev) => ({ ...prev, [id]: updatedCp }));
      console.log(cps);
    } catch (error) {
      console.error("Error fetching cp details:", error);
    }
  };

  useEffect(() => {
    consultants.forEach((consultant) => {
      if (consultant.cp_id && !cps[consultant.cp_id]) {
        fetchCp(consultant.cp_id);
      }
    });
  }, [consultants]);

  const handlebook = () => {
    setShowexisting(true);
  };
  const [consultantid, setConsultantid] = useState();
  const handleStatusClick = (consultationId, currentStatus) => {
    switch (currentStatus) {
      case -1:
        handleDeclined(consultationId);
        break;
      case 0:
        handleRequested(consultationId);
        break;
      case 1:
        handleApproved(consultationId);
        break;
      case 2:
        handleCompleted(consultationId);
        break;
      case 3:
        handleClosed(consultationId);
        break;
      default:
        console.error("Invalid status");
    }
  };



  const handleDeclined = (id) => {
    console.log(id);
    setConsultantid(id);

    setShoweditc(true);
  };

  const handleRequested = (id) => {
    setConsultantid(id);

    setShoweditc(true);
    console.log(id);
  };

  const handleApproved = (id) => {
    setShowConsultationCompleted(true);
    setConsultantid(id);
  };

  const handleCompleted = (id) => {
    setShowConsultationCompleted(true);
    setConsultantid(id);
  };

  const handleClosed = (id) => {};

  return (
    <>
      <div
        className={`
          
        ${showexisting ? " blur-sm " : ""} 
        ${showConsultationCompleted ? " blur-sm " : ""} 
        ${showConsultation ? "blur-sm" : ""}
        ${showExistingPatientList ? "blur-md" : ""}
        ${showEditPatient ? "blur-md" : ""}
                ${showeditc ? "blur-md" : ""}

        `}
      >
        {/* Heading Section */}
        <div className="ml-4 font-bold text-xl font-display text-gray-700 underline decoration-gray-500">
          My Consultation
        </div>

        {/* Today's Schedule Card */}
        <div className="shadow-sm grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-7 p-4 h-auto justify-items-center border rounded-xl bg-gray-100">
          {/* Heading */}
          <div className="col-span-1 lg:col-span-1 ml-6 underline text-gray-600 font-bold text-center lg:text-left mb-2 lg:mb-0">
            Today's Schedule
          </div>

          {/* Statistics */}
          <div className="grid grid-cols-3 col-span-1 lg:col-span-1 gap-2 text-center mb-2 lg:mb-0">
            <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#8ADBCA] text-lg">
              <span className="font-semibold text-[#0E044A] underline">
                {consultants.length}
              </span>
              <span className="inline-block text-[#0E044A] font-medium">
                Total Call
              </span>
            </div>
            <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#7CA7FC40] text-lg">
              <span className="font-semibold text-[#0E044A] underline">
                {statusCounts.completed}
              </span>
              <span className="inline-block text-[#0E044A] font-medium">
                Done Call
              </span>
            </div>
            <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#B34C6540] text-lg">
              <span className="font-semibold text-[#0E044A] underline">
                {statusCounts.requested}
              </span>
              <span className="inline-block text-[#0E044A] font-medium">
                Pending Call
              </span>
            </div>
          </div>

          {/* Date Picker */}
          <div className="col-span-1 lg:col-span-1 flex items-center justify-center lg:justify-end mt-4 lg:mt-0">
            <CalendarMonthIcon className="mr-2" />
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              className="rounded-md bg-white p-1 border border-gray-300"
              placeholderText="Select Date"
              dateFormat="yyyy-MM-dd" // Set display format to match "YYYY-MM-DD"
            />
          </div>
        </div>

        {/* Consultation List Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 justify-between mt-5">
          <div className="font-xl font-semibold underline font-display text-gray-800 mb-2 sm:mb-0">
            Consultation List
          </div>
          <div className="text-center sm:text-end">
            <button
              className="bg-[#0E044A] px-4 py-2 text-white rounded-md"
              onClick={handlebook}
            >
              + Book Consultation
            </button>
          </div>
        </div>
        {/* Consultation Cards */}
        {consultants.map((consultant, index) => {
          const patient = patients[consultant.patient_id];
          const cp = cps[consultant.cp_id];
          // updatedStatus(consultant.id, consultant.status);
          return (
            <div
              key={consultant.id}
              className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 bg-white shadow-sm rounded-xl p-4"
            >
              {/* Doctor Info */}
              <div className="flex items-center">
                <img
                  src={patient?.documentUrl || avatar}
                  alt="Avatar"
                  className="contrast-125 h-16 w-16"
                />
                <div className="ml-3">
                  <span className="block font-semibold text-gray-700">
                    Name: {patient?.name || "Loading..."}
                    {/* Use optional chaining */}
                  </span>
                  <span className="block text-gray-500">
                    ID: {consultant.patient_id}
                  </span>
                </div>
              </div>

              {/* Consultation ID */}
              <div className="flex items-center justify-center flex-col text-gray-700 font-semibold">
                <p>{consultant.id}</p>
                <p className={``}>
                  Status:{" "}
                  <span
                    className={`cursor-pointer ${
                      consultant.status === -1
                        ? "text-red-500"
                        : consultant.status === 0
                        ? "text-yellow-500"
                        : consultant.status === 1
                        ? "text-green-500"
                        : consultant.status === 2
                        ? "text-blue-500"
                        : "text-gray-500"
                    }`}
                    onClick={() =>
                      handleStatusClick(consultant.id, consultant.status)
                    }
                  >
                    {consultant.status === -1
                      ? "Declined"
                      : consultant.status === 0
                      ? "Requested"
                      : consultant.status === 1
                      ? "Approved"
                      : consultant.status === 2
                      ? "Completed"
                      : "Closed"}
                  </span>
                </p>{" "}
              </div>

              {/* Expand Button */}
              <div
                className="flex justify-end items-center cursor-pointer text-blue-500"
                onClick={() =>
                  setExpandedIndex(expandedIndex === index ? null : index)
                }
              >
                <ExpandMoreIcon />
              </div>

              {/* Additional Info (Visible on Expand) */}
              {expandedIndex === index && (
                <>
                  <div className="col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center gap-4 mt-4">
                    <div className="flex flex-col">
                      <span className="font-medium">
                        {consultant.meeting_id}
                      </span>
                      <span className="text-gray-500">Age: {cp.age}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-medium">Time:</span>
                      {/* <DatePicker
                      selected={selectedDate}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Select Time"
                      className="w-full bg-white p-1 border border-gray-300 rounded-md"
                    /> */}
                      <span className="text-gray-500">Sex: {cp.gender}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-medium">CP Name:</span>
                      <span className="text-gray-500">
                        {cp.first_name} {cp.middle_name} {cp.last_name}
                      </span>
                    </div>
                  </div>

                  {/* Action Buttons (Visible on Expand) */}
                  <div className="col-span-3 flex justify-end mt-4">
                    <button
                      className="border-2 rounded-md bg-[#0E044A] text-white border-[#0E044A] h-8 w-14 text-center "
                      onClick={() =>
                        handleViewAllDocuments(consultant.documents)
                      }
                    >
                      <DescriptionIcon />
                    </button>

                    <button
                      className="border rounded-md bg-[#0E044A] text-white h-8 w-14 text-center mx-2"
                      onClick={() =>
                        handleJoinRoom(
                          patient?.name || "guest",
                          consultant.meeting_id
                        )
                      }
                    >
                      <CallIcon />
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {showexisting && (
        <div className="xl:ml-[310px]">
          <Existing
            setShowexisting={setShowexisting}
            setExistingPatients={setExistingPatients}
            setShowExistingPatientList={setShowExistingPatientList}
          />
        </div>
      )}
      {/* success page */}

      {showExistingPatientList && (
        <div>
          <ExistingPatientList
            setShowexisting={setShowexisting}
            setShowExistingPatientList={setShowExistingPatientList}
            existingPatients={existingPatients}
            setEditPatientid={setEditPatientid}
            setShowEditPatient={setShowEditPatient}
            setShowConsultation={setShowConsultation}
            setConsultationid={setConsultationid}
          />
        </div>
      )}

      {showEditPatient && (
        <div>
          <EditPatient
            editPatientid={editPatientid}
            setShowEditPatient={setShowEditPatient}
          />
        </div>
      )}

      {/* Eclinic-New Consultant Instant/Schedule */}

      {showConsultation && (
        <div>
          <Newconsultation
            setShowConsultation={setShowConsultation}
            setShowExistingPatientList={setShowExistingPatientList}
            consultationid={consultationid}
          />
        </div>
      )}

      {showConsultationCompleted && (
        <div>
          <ConsultationCompleted
            setShowConsultationCompleted={setShowConsultationCompleted}
            consultantid={consultantid}
          />
        </div>
      )}

      {showeditc && (
        <div>
          <EditNewconsultation
            setShoweditc={setShoweditc}
            consultantid={consultantid}
          />
        </div>
      )}
    </>
  );
};

export default MyConsultation;
