import React, { useEffect, useState } from "react";
import { HiOutlineXCircle } from "react-icons/hi"; // Importing a close icon from react-icons
import Logo from "../cfcp_dashboard/images/Avtar.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import { collection, query, where, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase";
const statusOptions = ["Active", "Inactive"];

function ConsultantProvider({ setShowCp, id }) {
  const [info, setInfo] = useState(false);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  console.log(token);
  const [cpinfo, setCpinfo] = useState([]);
  const [cpid, setCpid] = useState("");
  const handleInfo = (item) => {
    setInfo(true);
    setCpinfo(item);
    setCpid(String(item.cp_details.cp_id));
    console.log(
      "gggggggggggggggggggggggggggggggggggggggggggg",
      item.cp_details.cp_id
    );
  };

  const handleInfoclose = () => {
    setInfo(false);
  };

  const heading = [
    { text: "Order" },
    { text: "Name" },
    { text: "ID" },
    { text: "Status" },
    { text: "Pending Requests" },
    { text: "Request" },
    { text: "Info" },
  ];

  const handleclose = () => {
    setShowCp(false);
  };
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [cp, setCp] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusParam = selectedStatus === "Active" ? 0 : 1;
        const res = await axios.get(
          `${BASE_URL}/api/consultationProviders/${statusParam}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setCp(res.data.results);

        console.log(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedStatus]);

  // const [selectedRow, setSelectedRow] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusParam = selectedStatus === "Active" ? 0 : 1;
        const res = await axios.get(
          `${BASE_URL}/api/consultationProviders/${statusParam}`,
          {
            headers: { Authorization: `${token}` },
          }
        );
        setCp(res.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedStatus]);

  const statusOptions = ["Active", "Inactive"];

  // const handleRowClick = (index) => {
  //   setSelectedRow(index === selectedRow ? null : index);
  // };

  /////////////////////firebase/////////////////////\
  const [availability, setAvailability] = useState([]);

  useEffect(() => {
    if (cpid) {
      const q = query(
        collection(db, "cp_availability"),
        where("__name__", "==", cpid)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched Data:", newData);

        // Extract availability from the first object
        if (newData.length > 0 && newData[0].availability) {
          setAvailability(newData[0].availability);
        } else {
          setAvailability([]);
        }
      });

      return () => unsubscribe();
    }
  }, [cpid]);

  const formatDate = (dateString) => {
    const today = new Date();
    const givenDate = new Date(dateString);

    if (givenDate.toDateString() === today.toDateString()) {
      return "Today";
    }

    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (givenDate.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    }

    return givenDate.toLocaleDateString();
  };

  ////////////////////firebase//////////////////////

  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const handleConfirm = async () => {
    if (!selectedTime || !selectedDate)
      return alert("Please select a time slot");

    console.log(id)
    

    const formattedStartTime = `${selectedDate} ${selectedTime}`;

    const requestBody = {
      cpId: cpid,
      consultationId: id,
      start_time: formattedStartTime,
    };

    console.log(requestBody)
    try {
      const res = await axios.post(
        `${BASE_URL}/api/assignCPbyCFCP`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", res);
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to assign. Please try again.");
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (index, id) => {
    setSelectedRow(index);
    setCpid(String(id));
  };

  const handleRequestClick = () => {
    if (!cpid) {
      alert("Please select a row first.");
      return;
    }
    setShowPopup(true);

    console.log("Selected CP ID:", cpid);
  };

  return (
    <div>
      {/* Main card */}
      <div
        className={`bg-white  rounded-md shadow-md p-4 min-h-[600px]    absolute top-0 right-0 left-0 min-h-full${
          info ? "opacity-15 pointer-events-none" : "opacity-100"
        }`}
      >
        <button onClick={handleclose} className="absolute top-6 right-4">
          <CancelIcon />
        </button>
        <h1 className="font-display   text-gray-700 font-bold text-xl underline decoration-1 decoration-gray-500 mb-4">
          Consultant Provider
        </h1>
        <div className="flex bg-gray-200 p-4 rounded-md mb-4">
          <div className="flex flex-col m-2 flex-1">
            <label htmlFor="search" className="font-semibold mb-1">
              Search
            </label>
            <input
              type="text"
              id="search"
              placeholder="Search by Name..."
              className="rounded-md h-9 w-full max-w-xs text-center border border-gray-400"
            />
          </div>
          <div className="flex flex-col m-2 flex-1">
            <label htmlFor="status" className="font-semibold mb-1">
              Status
            </label>
            <select
              id="status"
              className="rounded-md h-9 w-full max-w-xs text-center border border-gray-400"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              {statusOptions.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className=" overflow-x-auto overflow-y-auto max-h-80">
          {" "}
          <div className="overflow-x-auto overflow-y-auto max-h-80">
            <table className="border-collapse w-full text-center bg-white">
              <thead className="bg-gray-100">
                <tr>
                  {heading.map((item, index) => (
                    <th key={index} className="p-2">
                      {item.text}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {cp.map((item, index) => (
                  <tr
                    key={index}
                    className={`cursor-pointer ${
                      selectedRow === index ? "bg-blue-200" : ""
                    }`}
                    onClick={() => handleRowClick(index, item.cp_details.cp_id)}
                  >
                    <td className="p-2">{index + 1}</td>
                    <td className="p-2">{item.cp_details.firstName}</td>
                    <td className="p-2">{item.cp_details.cp_id}</td>
                    <td className="p-2">{selectedStatus}</td>
                    <td className="p-2">
                      {item.pending_count.Pending_requests}
                    </td>
                    <td className="p-2">
                      <input
                        type="radio"
                        checked={selectedRow === index}
                        readOnly
                      />
                    </td>
                    <td className="p-2">
                      <InfoIcon
                        onClick={() => handleInfo(item)}
                        className="cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            onClick={handleRequestClick}
            className="mt-4 p-2 bg-blue-500 text-white rounded-md"
          >
            Request
          </button>
        </div>
        {showPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-lg relative w-96">
      <button onClick={() => setShowPopup(false)} className="absolute top-2 right-2">
        <CancelIcon />
      </button>
      <h2 className="text-xl font-bold mb-3">Availability</h2>
      <div className="flex flex-wrap gap-3 mt-3">
        {availability?.length === 0 && (
          <p className="text-gray-500">No availability found.</p>
        )}
        {availability?.map((item) =>
          item.times?.map((time, index) => (
            <button
              key={`${item.date}-${index}`}
              className={`min-w-[100px] px-4 py-2 border rounded-lg text-sm shadow-sm transition-all
                ${selectedTime === time && selectedDate === item.date ? "bg-blue-500 text-white" : "bg-white border-gray-300 text-gray-700 hover:bg-gray-200"}`}
              onClick={() => {
                setSelectedTime(time);
                setSelectedDate(item.date);
              }}
            >
              <span className="block font-medium">{formatDate(item.date)}</span>
              <span className="block text-gray-600">{time}</span>
            </button>
          ))
        )}
      </div>
      <button
        className="mt-4 p-2 bg-green-500 text-white rounded-md w-full disabled:opacity-50"
        onClick={handleConfirm}
        disabled={!selectedTime}
      >
        Confirm
      </button>
    </div>
  </div>
)}

      </div>

      {/* Info Card with Cancel Icon */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${
          info ? "visible" : "hidden"
        }`}
      >
        <div
          className={`relative max-w-xl bg-white shadow-lg rounded-md p-4 ${
            window.innerWidth < 768 ? "max-h-fit p-2" : "max-h-[90vh]"
          }`}
        >
          {/* Cancel icon */}
          <button
            onClick={handleInfoclose}
            className="absolute top-2 right-2 text-gray-500 hover:text-red-500"
          >
            <HiOutlineXCircle size={25} /> {/* Reduced icon size */}
          </button>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {" "}
            {/* Reduced gap */}
            {/* Profile Section */}
            <div className="flex flex-col items-center">
              <div
                className={`text-md text-[#574C9D] font-mono font-semibold mb-1 ${
                  window.innerWidth < 768 ? "text-sm" : ""
                }`}
              >
                Profile
              </div>
              <div className="mb-1">
                <img
                  src={
                    cpinfo?.cp_details?.photo
                      ? `${BASE_URL}/api/image/${cpinfo.cp_details.photo}`
                      : "/default-profile.png"
                  } // Use a fallback image
                  alt="Profile Logo"
                  className={`w-16 h-16 rounded-full object-cover ${
                    window.innerWidth < 768 ? "w-12 h-12" : ""
                  }`}
                />

                {/* Reduced logo size */}
              </div>
              <div className="text-center">
                <span
                  className={`block font-semibold text-gray-700 ${
                    window.innerWidth < 768 ? "text-xs" : "text-sm"
                  }`}
                >
                  {cpinfo?.cp_details?.firstName} {cpinfo?.cp_details?.lastName}
                </span>
                <span
                  className={`block text-xs text-gray-500 font-semibold ${
                    window.innerWidth < 768 ? "text-xs" : "text-sm"
                  }`}
                >
                  {cpinfo?.INFO?.degree}
                </span>
                <span
                  className={`block text-xs text-gray-500 font-semibold ${
                    window.innerWidth < 768 ? "text-xs" : "text-sm"
                  }`}
                >
                  {cpinfo?.address_info?.line1} {cpinfo?.address_info?.city}
                </span>
                <span
                  className={`block text-xs text-gray-500 font-semibold ${
                    window.innerWidth < 768 ? "text-xs" : "text-sm"
                  }`}
                >
                  {cpinfo?.address_info?.state}
                </span>
              </div>
              <div className="mt-1">
                <span
                  className={`block text-[#574C9D] font-mono font-semibold ${
                    window.innerWidth < 768 ? "text-sm" : ""
                  }`}
                >
                  Experience
                </span>
                <span
                  className={`block text-xs text-gray-500 font-semibold ${
                    window.innerWidth < 768 ? "text-xs" : "text-sm"
                  }`}
                >
                  20+ years
                </span>
              </div>
            </div>
            {/* Speciality and Availability Section */}
            <div className="flex flex-col">
              <div>
                <div
                  className={`text-md text-[#574C9D] font-mono font-semibold mb-1 ${
                    window.innerWidth < 768 ? "text-sm" : ""
                  }`}
                >
                  Speciality
                </div>
                <div className="flex text-xs text-gray-600 space-x-2">
                  {" "}
                  {/* Reduced space */}
                  <div className="border border-gray-300 rounded-xl bg-gray-300 h-5 w-24 text-center font-semibold hover:bg-green-600 hover:text-white">
                    {cpinfo?.INFO?.specialty}
                  </div>
                  <div className="border border-gray-300 rounded-xl bg-gray-300 h-5 w-24 text-center font-semibold hover:bg-green-600 hover:text-white">
                    {cpinfo?.INFO?.sub_specialty}
                  </div>
                  {/* <div className="border border-gray-300 rounded-xl bg-gray-300 h-5 w-24 text-center font-semibold hover:bg-green-600 hover:text-white">
                    Psychology
                  </div> */}
                </div>
              </div>

              <div className="mt-2">
                {/* <div
  className={`text-md text-[#574C9D] font-mono font-semibold mb-1 ${
    window.innerWidth < 768 ? "text-sm" : ""
  }`}
>
  Availability
</div>

<div className="text-xs text-gray-500 grid grid-cols-2 gap-1">
  {availability?.length > 0 ? (
    availability.map((slot, index) =>
      (slot?.times || []).map((time, timeIndex) => (
        <div
          key={`${index}-${timeIndex}`}
          className="hover:bg-red-900 hover:border-0 hover:text-white border-2 border-gray-300 rounded-md font-semibold h-6 w-24 text-center flex justify-center items-center m-1"
        >
          {slot.date === new Date().toISOString().split("T")[0]
            ? `Today, ${time}`
            : `Tomorrow, ${time}`}
        </div>
      ))
    )
  ) : (
    <div className="text-gray-400 text-center col-span-2">No availability</div>
  )}
</div> */}

                <div className="p-4">
                  <h3 className="text-lg font-semibold text-indigo-700 mb-3">
                    Availability
                  </h3>

                  <div className="flex flex-wrap gap-3">
                    {availability?.length === 0 && (
                      <p className="text-gray-500">No availability found.</p>
                    )}

                    {availability?.map((item) =>
                      item.times?.map((time, index) => (
                        <button
                          key={index}
                          className="min-w-[100px] px-4 py-2 bg-white border border-gray-300 rounded-lg 
                            text-sm text-gray-700 shadow-sm hover:bg-gray-200 transition-all"
                        >
                          <span className="block font-medium">
                            {formatDate(item.date)}
                          </span>
                          <span className="block text-gray-600">{time}</span>
                        </button>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultantProvider;
