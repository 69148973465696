import React, { useEffect,useState,useCallback } from "react";
import pic from "../images/Avtar (1).svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import { BASE_URL } from "../../../utils/urlConfig";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const Conformation = ({ setShowConformation, consultantid, patinetid }) => {
  //  const handleViewAllDocuments = (documents) => {
  //     const prefix = `${BASE_URL}/api/pdf`;
  //     const docUrls = documents.split(",").map((url) => `${prefix}${url.trim()}`);
  //   };
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const [consultants, setConsultants] = useState([]);


  const [value, setValue] = useState();
    const [name, setName] = useState();
    const navigate = useNavigate();
    const handleJoinRoom = useCallback(
      (name, value) => {
        setValue(value);
        setName(name);
        console.log("Joining Room:", value, name);
    
       
    
        navigate(`/consultCp/room/${value}/${name}/${consultantid}/${patinetid}`);
    
        console.log("Navigating to room");
      },
      [navigate, value, consultantid, patinetid]
    );
    useEffect(() => {
      const fetchConsultation = async () => {
        try {
          const res = await axios.get(`${BASE_URL}/api/consultations/${consultantid}`, {
            headers: {
              Authorization: `${token}`,
            },
          });
  
          console.log("Fetched consultation data:", res.data);
  
          const consultation = res.data.consultation;
          const patient = consultation.patient_details;
  
          setConsultants(() => {
            const updatedData = {
              ...consultation,
              complaint_video: consultation.complaint_video
                ? `${BASE_URL}/api/video/${consultation.complaint_video.replace(
                    /^\//,
                    ""
                  )}`
                : null,
              complaint_audio: consultation.complaint_audio
                ? `${BASE_URL}/api/audio/${consultation.complaint_audio.replace(
                    /^\//,
                    ""
                  )}`
                : null,
              documents: consultation.documents
                ? consultation.documents.split(",").map((doc) => ({
                    name: doc.split("/").pop(), // Extracts document name
                    url: `${BASE_URL}/api/pdf/${doc.replace(/^\//, "")}`,
                  }))
                : [],
              photo: patient.photo
                ? `${BASE_URL}/api/image/${patient.photo.replace(/^\//, "")}`
                : null,
            };
  
            console.log("Updated consultation data:", updatedData);
            return updatedData;
          });
        } catch (error) {
          console.error("Error fetching consultation data:", error);
        }
      };
  
      fetchConsultation();
    }, [consultantid, token]); 

  
  
  const handleBack = () => {
    setShowConformation(false);
  };

  const handleViewDocument = (docUrl) => {
    window.open(docUrl, "_blank");
  };

  const handlePlayAudio = () => {
    if (consultants?.complaint_audio) {
      window.open(consultants.complaint_audio, "_blank");
    } else {
      alert("No audio available");
    }
  };

  const handlePlayVideo = () => {
    if (consultants?.complaint_video) {
      window.open(consultants.complaint_video, "_blank");
    } else {
      alert("No video available");
    }
  };
  return (
    <>
      <div
        className="absolute block top-24 ml-300px mx-auto rounded-lg overflow-hidden p-4"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className="bg-[#0E044A] text-white h-[100px] w-[500px] md:w-[800px] rounded-lg p-2 mx-auto shadow-lg relative">
          <p className="text-xl font-semibold text-[#EEEEEE] mb-4 underline top-[100px]">
            Consultation id:{consultants.id} 
          </p>
          <div className="flex flex-col items-center mb-6 absolute mx-auto left-1/2 -translate-x-1/2">
            <div className="h-[50px] w-[50px]">
              <img src={consultants.photo} alt="" />
            </div>
            <h3 className="text-lg text-[#3B3B3B] font-medium mt-2">
              {consultants?.patient_details?.name || "Loading..."}
            </h3>
          </div>
        </div>
        <div className="bg-white text-black w-[500px] md:w-[800px] rounded-lg p-6 mx-auto shadow-lg">
          <div className="mt-[80px] flex">
          <div className="w-1/2">
              {/* Chief Complaint */}
              <div>
                <h4 className="text-sm font-semibold underline">
                  Chief Complaint
                </h4>
                <p className=" text-sm mt-1">{consultants.chief_complaint}</p>
              </div>

              {/* Play Buttons */}
              <div className="flex gap-4 mt-3">
                <button
                  className="border bottom-[3px] border-[#0E044A] px-[10px] py-[5px] text-sm rounded-md hover:bg-blue-700 flex items-center"
                  onClick={handlePlayAudio}
                >
                  Play Audio
                </button>
                <button
                  className="border bottom-[3px] border-[#0E044A] px-[10px] py-[5px] text-sm rounded-md hover:bg-blue-700 flex items-center"
                  onClick={handlePlayVideo}
                >
                  Play Video
                </button>
              </div>
            </div>
            <div className="w-1/2">
              <h4 className="text-sm font-semibold underline">
                Consultation Records
              </h4>
              {consultants?.documents?.length > 0 ? (
                <ul className="mt-2 text-sm">
                  {consultants.documents.map((doc, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <span className="text-gray-400">
                        <DescriptionIcon />
                      </span>
                      <span>{doc.name}</span>
                      <span
                        className="text-gray-400 cursor-pointer"
                        onClick={() => handleViewDocument(doc.url)}
                      >
                        <VisibilityIcon />
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No documents available</p>
              )}
            </div>
          </div>

          <div className="flex justify-center mt-6 gap-[100px]">
            <button className="bg-blue-700 text-white px-6 py-2 rounded-md hover:bg-blue-800"
            onClick={() =>
              handleJoinRoom(
                consultants?.patient_details?.name || "Guest",
                consultants.meeting_id
              )
            }
            >
              Call
            </button>
            <button
              className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600"
              onClick={handleBack}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conformation;





