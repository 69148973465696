import React, { useState, useEffect } from "react";
import { MdCall, MdVideoCall } from "react-icons/md"; // Importing icons from react-icons
import CancelIcon from "@mui/icons-material/Cancel";
import Avatar from "../cfcp_dashboard/images/Avtar.svg";
import { BASE_URL } from "../../../utils/urlConfig";
import { useSelector } from "react-redux";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";

const Patientinfo = ({ setShowPi,id }) => {
  const handleclose = () => {
    setShowPi(false);
  };
  const [showprescription, setShowrescription] = useState(false);
  const [consultation, setConsultation] = useState();
  const [patient, setPatient] = useState([]);
  const [pic, setPic] = useState();
  const [age, setAge] = useState();
  const [audios, setAudio] = useState();
  const [video, setVideo] = useState();
  const [document, setDocument] = useState([]);
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Consultations
        const res = await axios.get(`${BASE_URL}/api/consultations/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log(res.data);
        setConsultation(res.data.consultation);

        // Patient
        console.log(res.data.consultation.patient_details);
        setPatient(res.data.consultation.patient_details);

        setPic(
          res.data.consultation.patient_details.photo
            ? `${BASE_URL}/api/image/${
                res.data.consultation.patient_details.photo.startsWith("/")
                  ? res.data.consultation.patient_details.photo.slice(1)
                  : res.data.consultation.patient_details.photo
              }`
            : ""
        );

        console.log(pic);

        // Handle audio
        const audioFile = res.data.consultation?.complaint_audio;
        if (audioFile) {
          const audioUrl = `${BASE_URL}/api/audio${
            audioFile.startsWith("/") ? audioFile : `/${audioFile}`
          }`;
          setAudio(audioUrl);
          console.log(audioUrl);
        } else {
          console.warn("No audio file available for this consultation.");
          setAudio("");
        }

        // Handle video
        const videoFile = res.data.consultation?.complaint_video;
        if (videoFile) {
          const videoUrl = `${BASE_URL}/api/video${
            videoFile.startsWith("/") ? videoFile : `/${videoFile}`
          }`;
          setVideo(videoUrl);
          console.log(videoUrl);
        } else {
          console.warn("No video file available for this consultation.");
          setVideo("");
        }

        // Handle multiple documents
        const documentsString = res.data.consultation.documents;
        const documentList = documentsString
          ? documentsString.split(",").map((url) => ({
              name: url.split("/").pop(),
              url: url.trim(),
            }))
          : [];
        setDocument(documentList);

        // Calculate age
        const dob = new Date(res.data.patient_details.dob);
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();

        const monthDifference = today.getMonth() - dob.getMonth();
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && today.getDate() < dob.getDate())
        ) {
          age--;
        }

        setAge(age);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const openAudioInNewTab = () => {
    if (!audios) {
      alert("No audio file available for playback.");
      return;
    }

    window.open(audios, "_blank");
  };

  const openVideoInNewTab = () => {
    if (!video) {
      alert("No video file available for playback.");
      return;
    }

    window.open(video, "_blank");
  };

  return (
    <div className="absolute top-0 left-0 right-0 h-[700px] md:h-[500px] bg-white shadow-lg overflow-hidden flex items-center justify-center rounded-lg">
      {/* Card */}
      <div className="relative p-8 rounded-lg shadow-lg w-full h-full">
        {/* Cancel Button */}
        <button onClick={handleclose} className="absolute top-6 right-4">
          <CancelIcon />
        </button>

        <h2 className="text-lg font-bold mb-5">Patient Details</h2>

        {/* Patient Description and Consultation Type */}
        <div className="flex mt-10 flex-col md:flex-row md:gap-4">
          <div className="border-2 border-gray-500 bg-gray-100 rounded-xl p-4 flex items-center mb-3 md:mb-0 md:w-1/2">
            <img
              src={pic}
              alt="Patient Avatar"
              className="h-20 w-20 md:h-24 md:w-24 rounded-full mb-2"
            />
            <div className="text-center text-sm md:text-base">
              <div>
                <span className="font-semibold">Name:</span>{" "}
                <span>{patient.name}</span>
              </div>
              <div>
                <span className="font-semibold">Patient ID:</span>{" "}
                <span>{patient.id}</span>
              </div>
              <div>
                <span className="font-semibold">Mobile No:</span>{" "}
                <span>{patient.mobile}</span>
              </div>
              <p className="md:hidden">
                <span className="font-semibold">Age:</span> <span>{age}</span>
              </p>
              <p className="md:hidden">
                <span className="font-semibold">Sex:</span>{" "}
                <span>{patient.gender}</span>
              </p>
            </div>
            <div className="hidden md:ml-4 md:block">
              <p>
                <span className="font-semibold">Age:</span> <span>{age}</span>
              </p>
              <p>
                <span className="font-semibold">Sex:</span>{" "}
                <span>{patient.name}</span>
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center md:relative md:left-12">
            <p className="font-semibold mb-1">Consultation Type</p>
            <div className="flex justify-center space-x-1">
              <button
                className={`rounded-lg border-gray-400 border-2 my-1 h-8 w-20 text-xs md:w-24 cursor-not-allowed ${
                  consultation?.status === 1
                    ? "bg-[#0E044A] text-white border-0"
                    : ""
                }`}
                disabled
              >
                Instant
              </button>

              <button
                className={`rounded-lg border-gray-400 border-2 my-1 h-8 w-20 text-xs md:w-24 cursor-not-allowed ${
                  consultation?.status === 2
                    ? "bg-[#0E044A] text-white border-0"
                    : ""
                }`}
                disabled
              >
                Schedule
              </button>
            </div>
          </div>
        </div>

        {/* Chief Complaint and Supporting Documents */}
        <div className="mt-6 flex flex-col md:flex-row md:gap-4">
          <div className="flex flex-col mb-3 w-full md:w-1/2">
            <label className="font-semibold mb-1 text-sm">
              Chief Complaint
            </label>

            <div className="border border-gray-400 rounded w-full p-2 resize-none text-sm">
              {consultation?.chief_complaint}
            </div>
          </div>

          <div className="flex flex-col mb-3 w-full md:w-1/2 relative md:left-28">
            <div className="text-lg font-semibold mb-2 flex items-center">
              Supporting Documents
              <label htmlFor="file-upload">
                <DescriptionIcon className="ml-auto cursor-pointer" />
              </label>
            </div>
            <div className="space-y-2">
              {document.map((doc, index) => {
                // Extract file name and extension
                const fileName = doc.name;
                const maxLength = 15;

                const shortName =
                  fileName.length > maxLength
                    ? `${fileName.slice(0, 8)}...${fileName.slice(-7)}`
                    : fileName;

                return (
                  <div
                    key={index}
                    className="flex items-center gap-2 border-b pb-2"
                  >
                    <DescriptionIcon />
                    <span>{`${index + 1}. ${shortName}`}</span>
                    <a
                      href={`https://api.chaoticchacha.in/api/pdf/${doc.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon className="ml-auto cursor-pointer" />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Audio and Video Call Options */}
        <div className="mt-4 flex flex-wrap space-x-1 justify-center">
          <span
            className="m-2 flex items-center justify-evenly hover:bg-[#0E044A] hover:text-white border-2 hover:border-0 border-gray-700 h-8 w-24 inline-block p-2 rounded-lg mb-1 text-md md:h-10 md:w-28 transition duration-200 ease-in-out hover:bg-opacity-90"
            onClick={openAudioInNewTab}
          >
            <MdCall className="mr-1" />
            <span className="font-semibold">Audio</span>
          </span>
          <span
            className="m-2 flex items-center justify-evenly hover:bg-[#0E044A] hover:text-white border-2 border-gray-700 hover:border-0 h-8 w-24 inline-block p-2 rounded-lg mb-1 text-md md:h-10 md:w-28 transition duration-200 ease-in-out hover:bg-opacity-90"
            onClick={openVideoInNewTab}
          >
            <MdVideoCall className="mr-1" />
            <span className="font-semibold">Video</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Patientinfo;
