import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import AddDrug from "./AddDrug";
import Avtar from "../images/Avtar (1).svg";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import axios from "axios";
import { successToast } from "../../../utils/toastConfig";
import { errorToast } from "../../../utils/toastConfig";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useParams, useNavigate } from "react-router-dom";

// {
//   "observation": "Patient shows signs of improvement.",
//   "plan_of_management": "Continue current medication and monitor blood pressure.",
//   "medications": [
//     {
//       "drug_id": 1,
//       "dose": 2,
//       "drug_freq_id": 2,
//       "comments": "Take after meals",
//       "duration": 7,
//       "period": "days",
//       "notes": "No side effects observed."
//     },
//     {
//       "drug_id": 1,
//       "dose": 5,
//       "drug_freq_id": 2,
//       "comments": "Take after meals",
//       "duration": 7,
//       "period": "days",
//       "notes": "No side effects observed."
//     }
//   ],
//   "lab_tests": [],  // List of lab test IDs
//   "followup_date": "2024-10-31",
//   "prescription_file": "path/to/prescription/file.pdf"
// }
const Addprescription = () => {
  const [expanded, setExpanded] = useState(false);
  const [showAD, setShowAD] = useState(false);
  const [consultant, setConsultant] = useState([]);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const { consultantid } = useParams();
  console.log("fffffffffffffffffffffffffffffffff",consultantid)
  const [drug, setDrug] = useState({
    drug_id: "",
    dose: "",
    drug_freq_id: "",
    comments: "",
    duration: "",
    period: "",
    notes: "",
  });
  console.log(drug);
  const [prescription, setPrescription] = useState({
    observation: "",
    plan_of_management: "",
    medications: [],
    lab_tests: [],
    followup_date: "",
    prescription_file: "",
  });
  const [labTests, setLabTests] = useState([]); // Stores the lab tests data
  const [selectedTests, setSelectedTests] = useState([]); // Stores selected lab tests
  const [showLabTests, setShowLabTests] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleAddDrug = () => {
    setShowAD(true);
  };
  console.log("Consultant ID:", consultantid);


  useEffect(() => {
    const fetchConsultation = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/consultations/${consultantid}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        console.log("Fetched consultation data:", res.data);

        const consultation = res.data.consultation;
        const patient = consultation.patient_details;

        setConsultant(() => {
          const updatedData = {
            ...consultation,
            photo: patient.photo
              ? `${BASE_URL}/api/image/${patient.photo.replace(/^\//, "")}`
              : null,
          };

          console.log("Updated consultation data:", updatedData);
          return updatedData;
        });
      } catch (error) {
        console.error("Error fetching consultation data:", error);
      }
    };

    fetchConsultation();
  }, [consultantid, token]);
  const handleSaveDrug = () => {
    // Create a copy of the current drug object
    const newDrug = { ...drug };

    // Validate if all fields are filled (optional)
    if (!newDrug.drug_id || !newDrug.dose || !newDrug.drug_freq_id) {
      errorToast("Please fill all required drug details.");
      return;
    }

    // Update the prescription state with the new drug
    setPrescription((prev) => ({
      ...prev,
      medications: [...prev.medications, newDrug], // Push a copy of drug
    }));

    // Reset drug state
    setDrug({
      drug_id: "",
      dose: "",
      drug_freq_id: "",
      comments: "",
      duration: "",
      period: "",
      notes: "",
    });

    setShowAD(false);
  };


  const handleChange = (e) => {
    const { name: inputName, value } = e.target;

    if (inputName.startsWith("medications.")) {
      const fieldName = inputName.split(".")[1];
      setDrug((prev) => ({
        ...prev,
        [fieldName]: value,
      }));
    } else if (inputName.startsWith("lab_tests.")) {
      const fieldName = inputName.split(".")[1];
      setPrescription((prev) => ({
        ...prev,
        lab_tests: {
          ...prev.lab_tests,
          [fieldName]: value,
        },
      }));
    } else {
      setPrescription((prev) => ({
        ...prev,
        [inputName]: value,
      }));
    }
  };

  const handleDeleteDrug = (index) => {
    setPrescription((prev) => ({
      ...prev,
      medications: prev.medications.filter((_, i) => i !== index),
    }));
  };

  // add lab

  useEffect(() => {
    const fetchLabTests = async () => {
      try {
        const response = await axios.get(
          "https://api.chaoticchacha.in/api/pathology-tests/"
        );
        console.log(response.data);

        if (Array.isArray(response.data.pathologyTests)) {
          setLabTests(response.data.pathologyTests);
        } else {
          console.error(
            "pathologyTests is not an array:",
            response.data.pathologyTests
          );
        }
      } catch (error) {
        console.error("Error fetching lab tests", error);
      }
    };

    fetchLabTests();
  }, []);

  const addLabTest = (test) => {
    setSelectedTests((prevTests) => {
      const updatedTests = [...prevTests, { id: test.id, name: test.name }];
      setPrescription((prev) => ({
        ...prev,
        lab_tests: updatedTests.map((t) => t.id), // Store only IDs in prescription
      }));
      return updatedTests;
    });
  };

  const removeLabTest = (testId) => {
    setSelectedTests((prevTests) =>
      prevTests.filter((test) => test.id !== testId)
    );
  };

  const [prescriptionFile, setPrescriptionFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");

  const handlePrescriptionChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPrescriptionFile(file);
      setFileName(file.name);
      setUploadStatus("File ready to upload");
    } else {
      alert("Please upload a valid PDF file");
    }
  };

  const uploadPrescription = async () => {
    if (!prescriptionFile) {
      alert("Please select a file first");
      return null; // Return null if no file
    }

    const formData = new FormData();
    formData.append("document", prescriptionFile);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data.documentUrls[0]);

      return response.data.documentUrls[0]; // Return file URL
    } catch (error) {
      console.error("Error uploading prescription:", error);
      setUploadStatus("Upload Failed");
      return null;
    }
  };

  const handleDateChange = (date) => {
    if (!date) return; // Prevent errors if date is null
    const formattedDate = format(date, "yyyy-MM-dd"); // Ensure proper format
    setPrescription((prev) => ({
      ...prev,
      followup_date: formattedDate,
    }));
    setSelectedDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadedFileUrl = await uploadPrescription(); // Wait for file upload

    // if (!prescription.observation || !prescription.plan_of_management || !prescription.followup_date) {
    //   errorToast("Please enter all required fields.");
    //   return;
    // }

    const labTestIds = selectedTests.map((test) => test.id);

    const jsonData = {
      ...prescription,
      lab_tests: labTestIds,
      prescription_file: uploadedFileUrl || "",
    };

    console.log(jsonData);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/consultation/${consultantid}`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      successToast("Prescription submitted successfully!");
      console.log("Response:", response.data);
    } catch (error) {
      errorToast("An error occurred while submitting the prescription.");
      console.error("Error:", error.response?.data || error.message);
    }
  };

  return (
    <>
      <div
        className={`block absolute top-[86px] bottom-0  right-0 left-0 bg-gray-100 p-4 md:p-6  overflow-y-auto
              ${showAD ? "blur-sm" : ""}

     `}
      >
        <div className="col-span-1 lg:col-span-1 ml-6 underline text-gray-600 font-bold text-center lg:text-left mb-2 lg:mb-0">
          Consultation Details( Clinical)
        </div>
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 bg-white shadow-sm rounded-xl p-4">
          {/* Doctor Info */}
          <div className="flex items-center">
            <img
              src={consultant.photo || Avtar}
              alt="Avatar"
              className="contrast-125 h-16 w-16"
            />
            <div className="ml-3">
              <span className="block font-semibold text-gray-700">
                Name:{consultant?.patient_details?.name || "Loading..."}
              </span>
              <span className="block text-gray-500">
                D: {consultant?.patient_details?.id || "NULL"}
              </span>
            </div>
          </div>

          {/* Consultation ID */}
          <div className="flex items-center justify-center flex-col text-gray-700 font-semibold">
            <p>{consultantid}</p>
          </div>

          <div
            className="flex justify-end items-center cursor-pointer text-blue-500"
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </div>

          {/* Additional Info (Visible on Expand) */}
          {expanded && (
            <>
              <div className="col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-4 mt-4">
                <div className="flex flex-col">
                  <span className="font-medium">123456</span>
                  <span className="text-gray-500">Age: 40</span>
                  <span className="text-gray-500">
                    CP Name: {consultant.cp_First_Name}{" "}
                    {consultant.cp_Middle_Name} {consultant.cp_Last_Name}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-medium">
                    Time:{" "}
                    {new Intl.DateTimeFormat("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    }).format(
                      new Date(consultant?.meeting_meta?.start_time || "None")
                    )}
                  </span>

                  <span className="text-gray-500">
                    Sex: {consultant?.patient_details?.gender || "NONE"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Chief Complaint</p>
                  <p>{consultant.chief_complaint || "Not Available"}</p>
                </div>
                <div className=" flex justify-end mt-4">
                  <button className="border-2 rounded-md bg-[#0E044A] text-white border-[#0E044A] h-8 w-14 text-center ">
                    <DescriptionIcon />
                  </button>
                </div>
              </div>

              {/* Action Buttons (Visible on Expand) */}
            </>
          )}
        </div>
        {/* ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp */}

        <div className="flex w-full gap-4 p-4">
          <div className="w-2/5">
            <p className="font-medium text-sm mb-2">Your observation:</p>
            <textarea
              name="observation"
              className="w-full h-24 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your observations"
              value={prescription.observation}
              onChange={handleChange}
            ></textarea>
          </div>
          {/* lab test */}
          <div className="w-2/5">
            <div className="flex items-center mb-2">
              <p className="block font-medium text-sm mr-1">Lab test:</p>
              <p
                className="text-blue-600 text-sm font-medium hover:underline focus:outline-none"
                onClick={() => setShowLabTests(!showLabTests)}
              >
                Add Lab Test +
              </p>
            </div>
            <div className="flex flex-wrap gap-2 border-[1px] rounded h-[97px] p-1 border-gray-300">
              {/* Render selected lab tests */}
              {selectedTests.map((test) => (
                <div
                  key={test.id}
                  className="flex items-center h-[30px] border border-gray-300 rounded-full px-2 py-1"
                >
                  <span className="text-sm">{test.name}</span>
                  <button
                    className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                    onClick={() => removeLabTest(test.id)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>

            {/* Show lab tests when the "Add Lab Test" button is clicked */}
            {showLabTests && (
              <div className="mt-2 max-h-40 overflow-y-auto border border-gray-300 bg-gray-300 rounded p-2 absolute">
                {labTests.length > 0 ? (
                  labTests.map((test) => (
                    <div
                      key={test.id}
                      className="flex items-center justify-between p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => addLabTest(test)}
                    >
                      <span>{test.name}</span>
                    </div>
                  ))
                ) : (
                  <p>No lab tests available.</p>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <label
              htmlFor="prescriptionUpload"
              className={`border-dashed border-2 ${
                fileName ? "border-blue-500 text-blue-500" : "border-gray-300"
              } rounded-md w-48 h-20 flex items-center justify-center cursor-pointer hover:border-blue-500 hover:text-blue-500`}
            >
              <div className="flex items-center space-x-2">
                <span className="text-sm">
                  {fileName ? fileName : "Upload Prescription"}
                </span>
              </div>
              <input
                id="prescriptionUpload"
                type="file"
                accept=".pdf"
                onChange={handlePrescriptionChange}
                className="hidden"
              />
            </label>
          </div>
        </div>

        {/* 2 */}
        <div className="p-4">
          <div className="flex items-center justify-between mb-4 text-sm">
            <p className="font-medium">Prescribed Drugs:</p>
            <button
              className="text-blue-600 font-medium hover:underline focus:outline-none"
              onClick={handleAddDrug}
            >
              Add Drugs +
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300 text-sm text-left">
              <thead className="bg-gray-300">
                <tr>
                  <th className="border border-gray-300 px-4 py-2">Sl No.</th>
                  <th className="border border-gray-300 px-4 py-2">
                    Drug Type
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Drug Name
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Dose</th>
                  <th className="border border-gray-300 px-4 py-2">
                    Frequency
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Comments</th>
                  <th className="border border-gray-300 px-4 py-2">Duration</th>
                  <th className="border border-gray-300 px-4 py-2">Notes</th>
                  <th className="border border-gray-300 px-4 py-2">Actions</th>
                </tr>
              </thead>
              {/* const [localDrug, setLocalDrug] = useState({
                  drug_id: "",
                  dose: "",
                  drug_freq_id: "",
                  comments: "",
                  duration: "",
                  period: "",
                  notes: "",
                }); */}
              <tbody>
                {prescription.medications.map((med, index) => (
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">
                      {index + 1}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.drug_id}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.period}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.dose}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.drug_freq_id}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.comments}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.duration}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {med.notes}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 flex space-x-2">
                      <button className="text-blue-500 hover:text-blue-700 focus:outline-none">
                        ✎
                      </button>
                      <button
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                        onClick={handleDeleteDrug}
                      >
                        ✖
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* 3 */}
        <div className="flex flex-col justify-between items-center px-4 w-full">
          <div className="mb-3 ml-auto">
            <label
              for="next-consultation"
              className="block text-sm text-gray-600 mb-1"
            >
              Next consultation (optional)
            </label>
            <div className="relative">
              <div className="flex items-center">
                <CalendarMonthIcon className="mr-2" />
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  className="rounded-md bg-white p-1 border border-gray-300"
                  placeholderText="Select Date"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
          </div>

          <div className="flex w-full">
            <button
              className="bg-indigo-900 text-white px-6 py-2 mr-auto rounded-md hover:bg-indigo-700 focus:outline-none"
              onclick="goBack()"
            >
              Back
            </button>
            <button
              className="text-black font-medium hover:underline focus:outline-none ml-auto"
              onclick="preview()"
            >
              Preview
            </button>
            <button
              className="bg-indigo-900 text-white px-6 py-2 ml-4 rounded-md hover:bg-indigo-700 focus:outline-none"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {showAD && (
        <div>
          <AddDrug
            setShowAD={setShowAD}
            setDrug={setDrug}
            handleSaveDrug={handleSaveDrug}
          />
        </div>
      )}
    </>
  );
};

export default Addprescription;
