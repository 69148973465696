import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom"; // <-- import Link for navigation

import {
  faGooglePlay,
  faAppStoreIos,
} from "@fortawesome/free-brands-svg-icons";
import DashboardIcon from "../images/Ellipse 3.svg";
import ConsultationIcon from "../images/Ellipse 4.svg";
import PatientDashboardIcon from "../images/Ellipse 4.svg";
import MyOrderIcon from "../images/Ellipse 5 (1).svg";
import MyWalletIcon from "../images/Ellipse 6.svg";
import Logo from "../images/Logo.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SidebarForSmallScreenEClinic from "./MobileSidebar";
import avatar from "../images/Avtar (1).svg";
import CrossIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import axios from "axios";
import Reset from "./Reset";
import { BASE_URL } from "../../../utils/urlConfig"
import CpDashboard from "../Dashboard/CpDashboard";
import CpProfile from "./CpProfile";

const SidebarCp = () => {
  const [idcard, setIdCard] = useState(false);

  const [settingCard, setSettingCard] = useState(false);
  const [showProfile, setShowProfile]=useState(false);
  const setting = useRef(null);
  const settingMenu = useRef(null);
  const showidcard = useRef(null);
  const navigate = useNavigate();
  const handleOutsideCard = (event) => {
    if (showidcard.current && !showidcard.current.contains(event.target)) {
      setIdCard(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideCard);
  });
  const [pic, setPic] = useState();
  const [user, setUser] = useState([]);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data;

        const calculateAge = (dob) => {
          const [day, month, year] = dob.split("/").map(Number);
          const birthDate = new Date(year, month - 1, day);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          // Adjust age if the birthday hasn't occurred yet this year
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        };

        setUser({
          ...data,
          age: calculateAge(data.dob), // Add calculated age
        });
        console.log();
        setPic(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  const handleSettingSize = (event) => {
    if (
      setting.current &&
      !setting.current.contains(event.target) &&
      settingMenu.current &&
      !settingMenu.current.contains(event.target)
    ) {
      setSettingCard(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleSettingSize);
    // return () => document.removeEventListener("mousedown", handleSettingSize);
  });

  const handleSettingCard = () => {
    setSettingCard(!settingCard);
  };
  const sidebarMenu = [
    { text: "Dashboard", icon: DashboardIcon, url: "/CpDashboard" },
    { text: "Approved Consultation", icon: ConsultationIcon, url: "/Approved-Consultation" },

    { text: "All-Consultation", icon: MyOrderIcon, url: "/All-Consultation" },
    // { text: "My Wallet", icon: MyWalletIcon, url: "/mywalleteclinic" },
  ];
  const location = useLocation();

  // const [active, setActive] = useState("/CpDeshboard");
  // const handleClick = (url) => {
  //   setActive(url);
  // };

  // Time State and Effect to update every second
  const [currentTime, setCurrentTime] = useState(() => {
    const now = new Date();
    const formattedDate = `${now.getDate().toString().padStart(2, "0")}/${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${now.getFullYear()}`;
    return `${formattedDate} | ${now.toLocaleTimeString()}`;
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const formattedDate = `${now.getDate().toString().padStart(2, "0")}/${(
        now.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${now.getFullYear()}`;
      setCurrentTime(`${formattedDate} | ${now.toLocaleTimeString()}`);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const HandleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const [reset, setReset] = useState(false);
  const [email, setEmail] = useState();
  const handleReset = () => {
    setEmail(user.email);
    setReset(true);
  };
  const handleprofile =()=>{
    setShowProfile(true)
  }

  const handleStatus=()=>{
    try {
      axios.patch(`${BASE_URL}/apitoggle-status/cp`,{
        headers: {
          Authorization: `${token}`,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div
        className={`
      ${reset ? "blur-sm" : ""}
            ${showProfile ? "blur-sm" : ""}

      
    
    `}
      >
        {/* Full sidebar for screens md and above */}
        <div className="hidden md:flex  h-svh">
          {/* Sidebar */}
          <div className="w-64 bg-[#0E044A] text-white flex flex-col justify-between">
            <div className="mt-8">
              {/* Logo */}
              <h1 className="text-center">
                <img src={Logo} alt="Astha Logo" className="w-31 mx-auto" />
              </h1>

              {/* Sidebar Menu Items */}
              <ul className="mt-16 space-y-4 text-lg px-4 font-sans font-medium">
                {sidebarMenu.map((item, index) => (
                  <li
                    key={index}
                    className={`flex items-center p-1 hover:bg-gray-600 rounded-lg cursor-pointer ${
                      location.pathname === item.url ? "bg-gray-500" : ""
                    }`}
                    // onClick={() => handleClick(item.url)}
                  >
                    <span className="mr-3">
                      <img
                        src={item.icon}
                        className="h-5 w-5"
                        alt={item.text}
                      />
                    </span>
                    <Link to={item.url}>{item.text}</Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Footer */}
            <footer className="text-center py-6">
              <div>
                <h1 className="text-sm">Available On</h1>
                <div className="flex justify-center space-x-4 my-1">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faGooglePlay}
                      className="h-5 w-5 mr-1"
                    />{" "}
                    Playstore
                  </span>
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faAppStoreIos}
                      className="h-5 w-5 mr-1"
                    />{" "}
                    Appstore
                  </span>
                </div>
                <p className="text-xs">Made With ❤️ in India</p>
                <p className="text-xs">&copy; 2024 Astha</p>
              </div>
            </footer>
          </div>

          {/* Main content and top bar */}
          <div className="flex-1 flex flex-col relative">
            {/* Top bar */}


          
            <div className="bg-[#0E044A] text-white h-24 px-6 flex flex-col justify-between">
              {/* Time on top */}
              <div className="flex justify-end text-lg decoration-1 decoration-gray-500">
                {currentTime}
              </div>

              {/* Icons on top-right */}
              <div className="flex justify-end space-x-6 mb-2 mx-6">
                <span>
                  <NotificationsIcon />
                </span>
                <span ref={settingMenu}>
                  <SettingsIcon onClick={handleSettingCard} />
                </span>
                <span>
                  <AccountCircleIcon onClick={handleprofile} />
                </span>
              </div>
            </div>

            {/* Main content area */}
            <div className="p-8 bg-gray-300 overflow-auto h-full">
              <Outlet />
            </div>
          </div>
        </div>

        {/* Sidebar for small screens */}
        <div className="md:hidden visible">
          {/* Implement a separate sidebar for small screens if needed */}
          <SidebarForSmallScreenEClinic />
        </div>

        <div
          ref={setting}
          className={`contrast-150 border border-b-gray-400 absolute top-24 right-10 bg-gray-100 w-[175px] h-[265px] rounded-lg shadow-sm shadow-red-900	${
            settingCard ? "block" : "hidden"
          }`}
        >
          <ul className="grid grid-rows text-center leading-9	text-[#0E044A] font-bold text-md ">
            <li className="underline decoration-1 decoration-slate-400 cursor-pointer">
              About Us
            </li>
            <li
              className="underline decoration-1 decoration-slate-400 cursor-pointer"
              onClick={handleReset}
            >
              Reset Password
            </li>
            <li className="underline decoration-1 decoration-slate-400 cursor-pointer">
              Terms & Conditions
            </li>
            <li className="underline decoration-1 decoration-slate-400 cursor-pointer">
              Privacy Policy
            </li>
            <li className="underline decoration-1 decoration-slate-400 cursor-pointer">
              Check For Update
            </li>
            <li className="underline decoration-1 decoration-slate-400 cursor-pointer">
              Send Feedback
            </li>
            <li
              className="underline decoration-1 decoration-slate-400 cursor-pointer"
              onClick={HandleLogout}
            >
              Log Out
            </li>
          </ul>
        </div>
      </div>
      {reset && (
        <div>
          <Reset setReset={setReset} email={email} />
        </div>
      )}

{showProfile && (
        <div>
          <CpProfile setShowProfile={setShowProfile}/>
        </div>
      )}
    </>
  );
};

export default SidebarCp;
