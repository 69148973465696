import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import Patientinfo from "./Patientinfo";

const CommonQ = ({ setShowPi, setId }) => {
  const heading = [
    { text: "#" },
    { text: "Select" },
    { text: "Patient ID" },
    { text: "Consultation ID" },
    { text: "Documents" },
    { text: "CF Assigned" },
    { text: "CP Assigned" },
    { text: "Consultation Date" },
    { text: "Status" },
    { text: "Info" },
  ];
  const handlePatientinfo = (id) => {
    setShowPi(true);
    setId(id);
  };

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const [consultation, setConsultation] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCommonQData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/cfcp-commonQueue`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setConsultation(res.data.consultations);
      console.log(res.data.consultations);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCommonQData();
  }, [token]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelfAssign = async () => {
    setLoading(true);
    try {
      for (const id of selectedIds) {
        await axios.post(
          `${BASE_URL}/api/cfcp-selfAssign`,
          { consultation_id: id },
          {
            headers: { Authorization: `${token}` },
          }
        );
      }
      alert("Self-assigned successfully!");
      setSelectedIds([]);
      fetchCommonQData();
    } catch (error) {
      console.error("Error assigning consultations:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="overflow-x-auto">
        <div className="min-w-full">
          <div className="flex justify-center w-full sm:w-auto absolute right-4 top-4">
            <span className="h-8 font-semibold font-sans border border-gray-500 px-4 py-1 rounded-lg hover:bg-[#0E044A] hover:text-white text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm">
              <button
                onClick={handleSelfAssign}
                disabled={loading || selectedIds.length === 0}
              >
                {loading ? "Assigning..." : "+Self Assign"}
              </button>
            </span>
          </div>
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                {heading.map((item, index) => (
                  <th
                    key={index}
                    className="border p-2 text-left font-semibold text-gray-700"
                  >
                    {item.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {consultation.map((row, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="border p-2 flex">
                    {index + 1}
                  </td>
                  <td>
                    <input
                      type="radio"
                      checked={selectedIds.includes(row.id)}
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                  </td>
                  <td className="border p-2">{row.patient_id}</td>
                  <td className="border p-2">{row.id}</td>
                  <td className="border p-2">
                    <DescriptionIcon className="mr-2 cursor-pointer" />
                  </td>
                  <td className="border p-2">{row?.cf_cp_id ? "Yes" : "No"}</td>
                  <td className="border p-2">
                    {row?.consultation_provider_id ? "Yes" : "No"}
                  </td>
                  <td className="border p-2">
                    {new Date(row.consultation_date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td className="border p-2">
                    {row.consultation_status === 0 ? "No" : "Yes"}
                  </td>
                  <td className="border p-2">
                    <InfoIcon
                      onClick={() => {
                        handlePatientinfo(row.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CommonQ;
