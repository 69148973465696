import React, { useState } from "react";
import ConsultationDetails from "./ConsultationDetails";
import PastConsultation from "./PastConsultation";

const AllConsultation = () => {
  const [isOn, setIsOn] = useState(false);

  const toggle = () => setIsOn(!isOn);

  return (
    <>
      <div className="flex justify-between">
        <h3 className="ml-6 underline text-gray-600 font-bold text-center lg:text-left mb-2 lg:mb-0">{isOn ? "PAST CONSULTATION DETAILS" : "CONSULTATION DETAILS"}</h3>
        <div>
          <div className="flex items-center">
            <span className="mr-2 text-lg font-medium">
              {isOn ? "View All Consultation" : "View Past Consultation"}
            </span>
            <div
              className={`relative inline-block w-12 h-6 transition duration-200 ease-linear rounded-full ${
                isOn ? "bg-blue-500" : "bg-gray-500"
              }`}
              onClick={toggle}
            >
              <span
                className={`absolute left-0 inline-block w-6 h-6 transition duration-100 ease-in transform bg-blue-900 rounded-full ${
                  isOn ? "translate-x-full" : "translate-x-0"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {isOn ? <PastConsultation /> : <ConsultationDetails />}
      </div>
    </>
  );
};

export default AllConsultation;
