import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import avatar from "../images/Avtar (1).svg";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "../images/Consultation Graph.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import MyOrderIcon from "../images/Ellipse 5 (1).svg";
import List from "../images/Ellipse 8.svg"
import Medicine from "./Medicine"; 
const NDHDeshbboard = () => {
 
  const [pic, setPic] = useState();
  const [user, setUser] = useState([]);
  const [showMD, setShowMD]=useState(false)

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data;

        const calculateAge = (dob) => {
          const [day, month, year] = dob.split("/").map(Number);
          const birthDate = new Date(year, month - 1, day);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        };

        setUser({
          ...data,
          age: calculateAge(data.dob), // Add calculated age
        });
        console.log();
        setPic(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);


  const [totalRequests, setTotalRequests] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/allMedicines`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        const formattedData = res.data.medicineResults.map((medicine) => ({
          ...medicine,
          requested_date: medicine.requested_date.split("T")[0],
        }));
        
        setTotalRequests(formattedData.length);
        console.log(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [token]);
  const handleShowMD=()=>{
    setShowMD(true);
  }

  return (
    <>
      <div
        className={` relative
                   ${showMD ? "blur-sm" : ""}

        `}
      >
        <div className="p-4">
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#454545",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              textDecoration: "underline",
              textDecorationColor: "#454534",
              textDecorationThickness: "1px",
            }}
          >
            Dashboard
          </Typography>

          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 gap-4 h-full`}
          >
            <div className="shadow-xl h-36 bg-white border border-b-gray-400 rounded-lg flex items-center p-4 col-span-1 sm:col-span-2">
              {/* <img
                src={pic || avatar}
                alt=""
                className="h-16 sm:h-20 contrast-200"
              /> */}
              <div className="h-[150px] w-[130px] rounded-xl flex justify-center items-center">
                <img
                  src={pic || avatar}
                  alt=""
                  className=" rounded-full h-[100px] w-[100px]"
                />
              </div>
              <div className="ml-4">
                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400 pb-2"
                  sx={{ fontWeight: "200" }}
                >
                  {user.business_name}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400 pb-2"
                  sx={{ fontWeight: "200" }}
                >
                  NDH-ID:{user.ndhID}
                </Typography>

                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400"
                  sx={{ fontWeight: "400" }}
                >
                  {user.mobile}
                </Typography>
              </div>
            </div>

            <div className="shadow-xl h-36 bg-white border border-b-gray-400 rounded-lg col-span-1 sm:col-span-1 p-4 flex flex-col justify-between">
                <img src={MyOrderIcon} alt="" className="w-[50px]"
                onClick={handleShowMD}
                />
                <p className="text-[#0E044ACC] font-semibold"
                   onClick={handleShowMD}
                >Listed Drugs</p>
            </div>

            <div className=" shadow-xl bg-white border border-b-gray-400 rounded-lg p-4 col-span-1 h-36 flex flex-col justify-between text-[#271E5D]">
                <div className="flex">
                <p className="text-[#454545] font-bold text-[40px]">{totalRequests}</p>
                <img src={List} alt="" className="w-[30px]"/>
                </div>
                <p className="text-[#0E044ACC] font-semibold">Medicine Requests</p>
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
          <img src={Chart} alt="" className="w-full h-auto" />
          <img src={Chart} alt="" className="w-full h-auto" />
        </div>
      </div>
      {showMD&&(
        
       <div>
         <Medicine setShowMD={setShowMD}/>

     </div>
      )}
    </>
  );
};

export default NDHDeshbboard;
