import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Avtar from "../images/Avtar (1).svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState, useCallback } from "react";
import Addprescription from "./Addprescription"
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { BASE_URL } from "../../../utils/urlConfig";
import { useSelector } from "react-redux";
import Conformation from "./Conformation";

const ApprovedConsultation = () => {
  const [showCD, setShowCD] = useState(false);
  const [showConformation, setShowConformation] = useState(false);
  const [patients, setPatients] = useState({});
  const [consultantid, setconsultantid] = useState({});
  const [patinetid, setPatientid] = useState({});
  const handleConformationCard = (consultant, patient) => {
    setShowConformation(true);
    setconsultantid(consultant.id);
    setPatientid(consultant.patient_id);
  };
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const [consultants, setConsultants] = useState([]);
  const today = new Date();
  const initialFormattedDate = today.toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formatted = `${localDate.getFullYear()}-${String(
      localDate.getMonth() + 1
    ).padStart(2, "0")}-${String(localDate.getDate()).padStart(2, "0")}`;
    setFormattedDate(formatted);
    console.log("Formatted Date (after selection):", formatted);
  };
  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/consultations?date=${formattedDate}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        // console.log("xxxxxxxxxxxxxxxxxxxxx", formattedDate);
        console.log(res.data);
        setConsultants(res.data.consultations);
        calculateStatusCounts(res.data.consultations);
      } catch (error) {
        console.error("Error fetching consultations:", error);
      }
    };
    fetchConsultations();
  }, [formattedDate]);
  const fetchPatient = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/api/patient/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      const updatedPatient = {
        ...res.data,
        documentUrl: res.data.photo
          ? `${BASE_URL}/api/image/${
              res.data.photo.startsWith("/")
                ? res.data.photo.slice(1)
                : res.data.photo
            }`
          : null,
      };
      setPatients((prev) => ({ ...prev, [id]: updatedPatient }));
    } catch (error) {
      console.error("Error fetching patient details:");
    }
  };
  useEffect(() => {
    consultants.forEach((consultant) => {
      if (consultant.patient_id && !patients[consultant.patient_id]) {
        fetchPatient(consultant.patient_id);
      }
    });
  }, [consultants]);
  const [statusCounts, setStatusCounts] = useState({
    declined: 0,
    requested: 0,
    approved: 0,
    completed: 0,
    closed: 0,
  }); 
  const calculateStatusCounts = useCallback((consultants) => {
    const counts = {
      declined: 0,
      requested: 0,
      approved: 0,
      completed: 0,
      closed: 0,
    }; 
    consultants.forEach(({ status }) => {
      switch (status) {
        case -1:
          counts.declined++;
          break;
        case 0:
          counts.requested++;
          break;
        case 1:
          counts.approved++;
          break;
        case 2:
          counts.completed++;
          break;
        case 3:
          counts.closed++;
          break;
        default:
          break;
      }
    });
    setStatusCounts(counts);
  }, []);
  return (
    <>
      <div
        className={`
      ${showCD ? "blur-sm" : ""}
`}
      >
        <div className="shadow-sm grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-7 p-4 h-auto justify-items-center border rounded-xl bg-gray-100">
          {/* Heading */}
          <div className="col-span-1 lg:col-span-1 ml-6 underline text-gray-600 font-bold text-center lg:text-left mb-2 lg:mb-0">
            Delivery Analytics
          </div>

          {/* Statistics */}
          <div className="grid grid-cols-3 col-span-1 lg:col-span-1 gap-2 text-center mb-2 lg:mb-0">
            <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#8ADBCA] text-lg">
              <span className="font-semibold text-[#0E044A] underline">
                {consultants.length}
              </span>
              <span className="inline-block text-[#0E044A] font-medium text-[12px]">
                Total Call
              </span>
            </div>
            <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#7CA7FC40] text-lg">
              <span className="font-semibold text-[#0E044A] underline">
                {statusCounts.completed}
              </span>
              <span className="inline-block text-[#0E044A] font-medium text-[12px]">
                Done Call
              </span>
            </div>
            <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#B34C6540] text-lg">
              <span className="font-semibold text-[#0E044A] underline">
                {statusCounts.requested}
              </span>
              <span className="inline-block text-[#0E044A] font-medium text-[12px]">
                Pending Call
              </span>
            </div>
          </div>

          {/* Date Picker */}
          <div className="col-span-1 lg:col-span-1 flex items-center justify-center lg:justify-end mt-4 lg:mt-0">
            <CalendarMonthIcon className="mr-2" />
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              className="rounded-md bg-white p-1 border border-gray-300"
              placeholderText="Select Date"
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>
        <div className="pt-3">
          {consultants.map((consultant, index) => {
            const patient = patients[consultant.patient_id];
            // console.log(patient);
            return (
              <>
                <div className="bg-white shadow-md rounded-lg p-4 flex items-center h-[90px] mb-2">
                  <div className="flex items-center w-1/3">
                    <div className="mx-6">
                      <img
                        src={patient?.documentUrl || Avtar}
                        alt="Avatar"
                        className="contrast-125 h-16 w-16"
                      />
                    </div>
                    <div>
                      <h3 className="mb-[5px] font-medium">
                        {" "}
                        Name: {patient?.name || "Loading..."}
                      </h3>
                      <p>ID: {consultant.patient_id}</p>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <p className="mb-[5px]">
                      <span className="font-medium">Date & Time: </span>
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }).format(new Date(consultant.meeting_meta.start_time))}
                    </p>
                    <p>
                      <span className="font-medium">Sex: </span>
                      {patient?.gender}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-1/3">
                    <div>
                      <h3 className="text-[15px] font-medium">
                        Chief complaint
                      </h3>
                      <p className="text-[10px] ">
                        {consultant.chief_complaint || "Not Available"}
                      </p>
                    </div>
                    <div className="border-2 border-slate-400 rounded-lg cursor-pointer">
                      <ArrowForwardIcon
                        className="text-slate-400"
                        onClick={() =>
                          handleConformationCard(consultant, patient)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <Stack spacing={2} className="flex items-end mt-4">
          <Pagination
            // count={Math.ceil(filteredPatients.length / patientsPerPage)}
            // page={page}
            // onChange={handleChangePage} 
            color="primary"
          />
        </Stack>
      </div>
      {showConformation && (
        <div className="">
          <Conformation
            setShowConformation={setShowConformation}
            consultantid={consultantid}
            patinetid={patinetid}
          />
        </div>
      )}
    </>
  );
};

export default ApprovedConsultation;
