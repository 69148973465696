import React, { useState, useEffect } from "react";
import PatientIcon from "../images/Vector (1).svg";
import StethoscopeIcon from "../images/Ellipse 5.svg";
import CpAvailableIcon from "../images/2.svg";
import AssignedQ from "./AssignedQ";
import CommonQ from "./CommonQ";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import ConsultantProvider from "./ConsultantProvider";
import Patientinfo from "./Patientinfo";

function ConsultationQueue() {
  const [info, setInfo] = useState(false);
  const [showPi, setShowPi] = useState(false);
  const [id, setId] = useState();
  const [activeTab, setActiveTab] = useState("common");

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  /////////////////////////////unassigned patient//////////////////////////////////////
  const [patient, setPatient] = useState(0);
  useEffect(() => {
    const unassignedP = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/unassigned-Patients`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        console.log(res.data.patients.length);
        setPatient(res.data.patients.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    unassignedP();
  }, []);

  ////////////////////////////////////cp available///////////////////////////////

  const [cps, setCps] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cp = await axios.get(`${BASE_URL}/api/active-cps`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        setCps(cp.data.data.length); // Set the length of data array
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const [showCp, setShowCp] = useState(false);
  const [consultationid,setConsultationid]=useState("")

  return (
    <div className="relative">
      {/* Main Container */}
      <div
        className={`
          ${showCp ? "blur-sm" : ""}        
        `}
      >
        <div
          className={` pr-4 max-w-screen-xl mx-auto overflow-x-hidden ${
            info ? "bg-gray-300 bg-opacity-20 backdrop-blur-md" : "bg-gray-300"
          }`}
        >
          <h1 className="text-[#454545] font-semibold text-lg font-display underline decoration-1 mb-4">
            Consultation Queue
          </h1>

          {/* Cards Container */}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-8">
            <div className="bg-gray-100 flex flex-col sm:flex-row items-center justify-center border border-gray-400 h-auto p-4 rounded-xl">
              <img
                src={PatientIcon}
                alt="Patient Icon"
                className="h-10 w-10 sm:h-12 sm:w-12 mb-2 sm:mb-0 sm:mr-4 flex-shrink-0"
              />
              <div className="text-center sm:text-left flex-1 max-w-full break-words">
                <p className="text-[14px] sm:text-base lg:text-lg font-semibold underline decoration-1 decoration-slate-600 text-[#0E0A4A]">
                  Patients
                </p>
                <p className="text-sm sm:text-base lg:text-lg underline decoration-1 decoration-slate-600 text-red-600 font-semibold">
                  143
                </p>
              </div>
            </div>
            <div className="bg-gray-100 flex flex-col sm:flex-row items-center justify-center border border-gray-400 h-auto p-4 rounded-xl">
              <img
                src={StethoscopeIcon}
                alt="Stethoscope Icon"
                className="h-10 w-10 sm:h-12 sm:w-12 mb-2 sm:mb-0 sm:mr-4 flex-shrink-0"
              />
              <div className="text-center sm:text-left flex-1 max-w-full break-words">
                <p className="text-[14px] sm:text-base lg:text-lg font-semibold underline decoration-1 decoration-slate-600 text-[#0E0A4A]">
                  Unassigned Patients
                </p>
                <p className="text-sm sm:text-base lg:text-lg underline decoration-1 decoration-slate-600 text-red-600 font-semibold">
                  {patient}
                </p>
              </div>
            </div>
            <div className="bg-gray-100 flex flex-col sm:flex-row items-center justify-center border border-gray-400 h-auto p-4 rounded-xl">
              <img
                src={CpAvailableIcon}
                alt="CP Available Icon"
                className="h-10 w-10 sm:h-12 sm:w-12 mb-2 sm:mb-0 sm:mr-4 flex-shrink-0"
              />
              <div className="text-center sm:text-left flex-1 max-w-full break-words">
                <p className="text-[14px] sm:text-base lg:text-lg font-semibold underline decoration-1 decoration-slate-600 text-[#0E0A4A]">
                  CP Available
                </p>
                <p className="text-sm sm:text-base lg:text-lg underline decoration-1 decoration-slate-600 text-red-600 font-semibold">
                  {cps}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Button Section */}
        <div className="mt-4 bg-white p-4 max-w-screen-xl mx-auto rounded-lg shadow-lg relative">
          {/* Button Group and Table in one card */}
          <div className="flex flex-col w-full space-y-4">
            {/* Button Group */}
            <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
              {/* Queue Buttons */}
              <div className="flex flex-row justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-4">
                <button
                  onClick={() => setActiveTab("common")}
                  className={`h-8 font-semibold font-sans border border-gray-500 px-4 py-1 rounded-lg text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm ${
                    activeTab === "common"
                      ? "bg-[#0E044A] text-white"
                      : "hover:bg-[#0E044A] hover:text-white"
                  }`}
                >
                  Common Queue
                </button>
                <button
                  onClick={() => setActiveTab("assigned")}
                  className={`h-8 font-semibold font-sans border border-gray-500 px-4 py-1 rounded-lg text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm ${
                    activeTab === "assigned"
                      ? "bg-[#0E044A] text-white"
                      : "hover:bg-[#0E044A] hover:text-white"
                  }`}
                >
                  Assigned Queue
                </button>
              </div>

              {/* Self Assign Button */}
            </div>

            {/* Table */}
            <div className="mt-4">
              {activeTab === "common" ? (
                <CommonQ setShowPi={setShowPi} setId={setId} />
              ) : (
                <AssignedQ
                  setShowPi={setShowPi}
                  setId={setId}
                  setShowCp={setShowCp}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* patient info */}

      {/* Consultatnt Provider */}
      {showCp && <ConsultantProvider setShowCp={setShowCp}/>}

      {showPi && <Patientinfo setShowPi={setShowPi} id={id} />}
    </div>
  );
}

export default ConsultationQueue;
