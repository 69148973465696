import React, { useState, useEffect } from 'react'
import Avtar from "../images/Avtar (1).svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ConsultationDetailsCard from './ConsultationDetailsCard';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import axios from "axios"
const PastConsultation = () => {
  const [showCD, setShowCD] = useState(false);
  const [showConformation, setShowConformation] = useState(false);
  const [patients, setPatients] = useState({});
  const [consultant, setconsultant] = useState({});
  const [patinet, setPatient] = useState({});
  const handleConformationCard = (consultant, patient) => {
    setShowConformation(true);
    setconsultant(consultant);
    setPatient(patient);
  };
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const [consultants, setConsultants] = useState([]);
  const today = new Date();
  const initialFormattedDate = today.toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formatted = `${localDate.getFullYear()}-${String(
      localDate.getMonth() + 1
    ).padStart(2, "0")}-${String(localDate.getDate()).padStart(2, "0")}`;
    setFormattedDate(formatted);
    console.log("Formatted Date (after selection):", formatted);
  };
    const[showDT, setShowDT]=useState(false)
    const handledetailsCard =()=>{
        setShowDT(true)
    }

    useEffect(() => {
      const fetchConsultations = async () => {
        try {
          const res = await axios.get(
            `${BASE_URL}/api/consultation/cp`,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          // console.log("xxxxxxxxxxxxxxxxxxxxx", formattedDate);
          console.log(res.data);
          setConsultants(res.data.consultations);
        } catch (error) {
          console.error("Error fetching consultations:", error);
        }
      };
      fetchConsultations();
    }, [formattedDate]);
    const fetchPatient = async (id) => {
      try {
        const res = await axios.get(`${BASE_URL}/api/patient/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        const updatedPatient = {
          ...res.data,
          documentUrl: res.data.photo
            ? `${BASE_URL}/api/image/${
                res.data.photo.startsWith("/")
                  ? res.data.photo.slice(1)
                  : res.data.photo
              }`
            : null,
        };
        setPatients((prev) => ({ ...prev, [id]: updatedPatient }));
      } catch (error) {
        console.error("Error fetching patient details:");
      }
    };
    useEffect(() => {
      consultants.forEach((consultant) => {
        if (consultant.patient_id && !patients[consultant.patient_id]) {
          fetchPatient(consultant.patient_id);
        }
      });
    }, [consultants]);
  return (
    <div className='mt-12'>
       
      <div className="pt-3">
          {consultants.map((consultant, index) => {
            const patient = patients[consultant.patient_id];
            // console.log(patient);
            return (
              <>
                <div className="bg-white shadow-md rounded-lg p-4 flex items-center h-[90px] mb-2">
                  <div className="flex items-center w-1/3">
                    <div className="mx-6">
                      <img
                        src={patient?.documentUrl || Avtar}
                        alt="Avatar"
                        className="contrast-125 h-16 w-16"
                      />
                    </div>
                    <div>
                      <h3 className="mb-[5px] font-medium">
                        {" "}
                        Name: {patient?.name || "Loading..."}
                      </h3>
                      <p>ID: {consultant.patient_id}</p>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <p className="mb-[5px]">
                      <span className="font-medium">Date & Time: </span>
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }).format(new Date(consultant.meeting_meta.start_time))}
                    </p>
                    <p>
                      <span className="font-medium">Sex: </span>
                      {patient?.gender}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-1/3">
                    <div>
                      <h3 className="text-[15px] font-medium">
                        Chief complaint
                      </h3>
                      <p className="text-[10px] ">
                        {consultant.chief_complaint || "Not Available"}
                      </p>
                    </div>
                    <div className="border-2 border-slate-400 rounded-lg cursor-pointer">
                      <ArrowForwardIcon
                        className="text-slate-400"
                        onClick={() =>
                          handleConformationCard(consultant, patient)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      


      <Stack spacing={2} className="flex items-end mt-4">
          <Pagination
            // count={Math.ceil(filteredPatients.length / patientsPerPage)}
            // page={page}
            // onChange={handleChangePage}
            color="primary"
          />
        </Stack>
      {showDT && (
        <div className="">
          <ConsultationDetailsCard />
        </div>
      )}
    </div>
  )
}

export default PastConsultation
