import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import avatar from "../images/Avtar (1).svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import epatientIcon from "../images/Vector (3).svg";
import npatientIcon from "../images/Vector (2).svg";
import Chart from "../images/Consultation Graph.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";
import Register from "./Register";
import Existing from "./Existing";
import RegisterSuccess from "./RegisterSuccess";
import ExistingPatientList from "./ExistingPatientList";
import EditPatient from "./EditPatient";
import Newconsultation from "./Newconsultation";

function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [registerPatient, setRegisterPatient] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [showexisting, setShowexisting] = useState(false);
  const [showExistingPatientList, setShowExistingPatientList] = useState(false);
  const [existingPatients, setExistingPatients] = useState([]);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [editPatientid, setEditPatientid] = useState();
  const [showConsultation, setShowConsultation] = useState(false);
  const [consultationid, setConsultationid] = useState();
  const [id, setId] = useState();
  const [pic, setPic] = useState();
  const [user, setUser] = useState([]);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data;

        const calculateAge = (dob) => {
          const [day, month, year] = dob.split("/").map(Number);
          const birthDate = new Date(year, month - 1, day);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          // Adjust age if the birthday hasn't occurred yet this year
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        };

        setUser({
          ...data,
          age: calculateAge(data.dob), // Add calculated age
        });
        console.log();
        setPic(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(new Date("2024-01-01"));
  const [endDate, setEndDate] = useState(new Date());
  const [consultants, setConsultants] = useState([]);

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/consultationsbydate?startDate=${formatDate(
            startDate
          )}&endDate=${formatDate(endDate)}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setConsultants(res.data.consultations);
      } catch (error) {
        console.error("Error fetching consultations:", error);
      }
    };

    if (startDate && endDate) {
      fetchConsultations();
    }
  }, [startDate, endDate]);

  return (
    <>
      <div
        className={`
          ${registerPatient ? "blur-sm" : ""}
          ${showSuccessCard ? "	blur-sm" : ""}
          ${showexisting ? " blur-sm " : ""} 
          ${showConsultation ? "blur-sm" : ""}
        ${showExistingPatientList ? "blur-md" : ""}
        ${showEditPatient ? "blur-md" : ""}
        
        `}
      >
        <div className="p-4">
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#454545",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              textDecoration: "underline",
              textDecorationColor: "#454534",
              textDecorationThickness: "1px",
            }}
          >
            Dashboard
          </Typography>

          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 gap-4 h-full`}
          >
            <div className="shadow-xl h-36 bg-white border border-b-gray-400 rounded-lg flex items-center p-4 col-span-1 sm:col-span-2">
              {/* <img
                src={pic || avatar}
                alt=""
                className="h-16 sm:h-20 contrast-200"
              /> */}
              <div className="h-[150px] w-[130px] rounded-xl flex justify-center items-center">
                <img
                  src={pic || avatar}
                  alt=""
                  className=" rounded-full h-[100px] w-[100px]"
                />
              </div>
              <div className="ml-4">
                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400 pb-2"
                  sx={{ fontWeight: "200" }}
                >
                  {user.id} | {user.first_name} {user.middle_name}{" "}
                  {user.last_name}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400"
                  sx={{ fontWeight: "400" }}
                >
                  {user.mobile}
                </Typography>
              </div>
            </div>

            <div className="shadow-xl h-36 bg-white border border-b-gray-400 rounded-lg col-span-1 sm:col-span-3 p-4 flex flex-col justify-between">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <div className="flex items-center">
                  <CalendarMonthIcon className="mr-2" />
                  <span className="underline">From:</span>
                  <DatePicker
                    className="w-24 pl-1 rounded-lg ml-2"
                    selected={startDate}
                    onChange={(date) => handleDateChange(date, "start")}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

                <div className="flex items-center">
                  <CalendarMonthIcon className="mr-2" />
                  <span className="underline">To:</span>
                  <DatePicker
                    className="w-24 pl-1 rounded-lg ml-2"
                    selected={endDate}
                    onChange={(date) => handleDateChange(date, "end")}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div className="text-center border-2 border-gray-300 rounded-lg text-[#0E044ACC] p-2">
                  <Typography
                    className="underline decoration-gray-400"
                    sx={{ fontWeight: "700", fontSize: "1rem" }}
                  >
                    {consultants.length}
                  </Typography>
                  <Typography
                    className="underline decoration-gray-400"
                    sx={{
                      fontWeight: "700",
                      fontSize: { xs: "0.6rem", md: "0.8rem", lg: "1rem" },
                    }}
                  >
                    Total Orders
                  </Typography>
                </div>
                <div className="text-center border-2 border-gray-300 rounded-lg text-[#0E044ACC] p-2">
                  <Typography
                    className="underline decoration-gray-400"
                    sx={{ fontWeight: "700", fontSize: "1rem" }}
                  >
                    01
                  </Typography>
                  <Typography
                    className="underline decoration-gray-400"
                    sx={{
                      fontWeight: "700",
                      fontSize: { xs: "0.6rem", md: "0.8rem", lg: "1rem" },
                    }}
                  >
                    Total Consultations
                  </Typography>
                </div>
              </div>
            </div>

            <div className=" shadow-xl bg-white border border-b-gray-400 rounded-lg p-4 col-span-1 h-36 flex flex-col justify-between text-[#271E5D]">
              <Typography
                variant="h6"
                component="div"
                className=" flex justify-center items-center space-x-2 underline decoration-gray-400 cursor-pointer"
                sx={{ fontSize: "0.7rem", fontWeight: "700" }}
                onClick={() => setShowexisting(!showexisting)}
              >
                {/* .................................................................. */}
                <img src={epatientIcon} alt="" className="h-6 w-6" />
                <p> Existing Patients</p>
              </Typography>
              <hr className="border-2 border-[#271E5D] mx-4" />
              <Typography
                variant="h6"
                component="div"
                className="flex justify-center items-center space-x-2 underline decoration-gray-400 cursor-pointer"
                sx={{ fontSize: "0.7rem", fontWeight: "700" }}
              >
                <img src={npatientIcon} alt="" className="h-6 w-6" />
                <p onClick={() => setRegisterPatient(!registerPatient)}>
                  New Patients
                </p>
              </Typography>
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
          <img src={Chart} alt="" className="w-full h-auto" />
          <img src={Chart} alt="" className="w-full h-auto" />
        </div>
      </div>
      {/* Register Patient card */}
      {registerPatient && (
        <div className="xl:ml-[110px] ">
          <Register
            setRegisterPatient={setRegisterPatient}
            setShowSuccessCard={setShowSuccessCard}
            setPatientData={setPatientData}
            setId={setId}
          />
        </div>
      )}
      {/* existing Patient card */}
      {showexisting && (
        <div className="xl:ml-[310px]">
          <Existing
            setShowexisting={setShowexisting}
            setExistingPatients={setExistingPatients}
            setShowExistingPatientList={setShowExistingPatientList}
          />
        </div>
      )}
      {/* success page */}
      {showSuccessCard && (
        <div className="xl:ml-[310px]">
          <RegisterSuccess
            patientData={patientData}
            setShowSuccessCard={setShowSuccessCard}
            id={id}
          />
        </div>
      )}

      {showExistingPatientList && (
        <div>
          <ExistingPatientList
            setShowexisting={setShowexisting}
            setShowExistingPatientList={setShowExistingPatientList}
            existingPatients={existingPatients}
            setEditPatientid={setEditPatientid}
            setShowEditPatient={setShowEditPatient}
            setShowConsultation={setShowConsultation}
            setConsultationid={setConsultationid}
          />
        </div>
      )}

      {showEditPatient && (
        <div className="xl:ml-[110px] ">
          <EditPatient
            editPatientid={editPatientid}
            setShowEditPatient={setShowEditPatient}
          />
        </div>
      )}

      {/* Eclinic-New Consultant Instant/Schedule */}

      {showConsultation && (
        <div>
          <Newconsultation
            setShowConsultation={setShowConsultation}
            setShowExistingPatientList={setShowExistingPatientList}
            consultationid={consultationid}
          />
        </div>
      )}

      {/* Consultant Providers Card */}

      {/* this is for video recording */}

      {/* this is for audio recording */}
    </>
  );
}

export default Dashboard;
