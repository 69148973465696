import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { BASE_URL } from "../../../utils/urlConfig";

function Room() {
  const { roomID, name } = useParams();
  const navigate = useNavigate();

  const myMeeting = async (element) => {
    const appID = 1705247521;
    const serverSecret = "0cd6e2a9e6e4a932cb925f7bae8c965b";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      Date.now().toString(),
      name
    );

    const zc = ZegoUIKitPrebuilt.create(kitToken);

    zc.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: "COPY LINK",
          url: `${BASE_URL}/room/${roomID}`,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall,
      },
      turnOnCameraWhenJoining: false,
      turnOnMicrophoneWhenJoining: false,
    });
  };

  return (
    <div>
      <div
        ref={myMeeting}
        className="block absolute top-0 bottom-0  right-0 left-0"
      />
    </div>
  );
}

export default Room;
