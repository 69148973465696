import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import avatar from "../images/Avtar (1).svg";
import DescriptionIcon from "@mui/icons-material/Description";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { BASE_URL } from "../../../utils/urlConfig";
import { useSelector } from "react-redux";


function MyOrders() {
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getMedicinesByAgent`,
          {
            headers: {
              Authorization: ` ${token}`,
            },
          }
        );
        console.log(response.data);
        setOrders(response.data.results);
        console.log(
          
          response.data.results
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // const handleFile = (e, orderId) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const updatedOrders = orders.map((order) =>
  //       order.orderId === orderId ? { ...order, document: file.name } : order
  //     );
  //     setOrders(updatedOrders);
  //   }
  // };

  //   const getProgressWidth = (status) => {
  //     switch (status) {
  //       case "Order Placed":
  //         return "w-1/5";
  //       case "Confirmed":
  //         return "w-2/5";
  //       case "Dispatched":
  //         return "w-3/5";
  //       case "Out for Delivery":
  //         return "w-4/5";
  //       case "Delivered":
  //         return "w-full";
  //       default:
  //         return "w-1/5";
  //     }
  //   };

  /////////////otp//////////////
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [oid, setOid] = useState("");
  const handleOtp = async ({ patient_id, orderId }) => {
    setOid(orderId);

    try {
      const res = await axios.post(
        `${BASE_URL}/api/sendOtpToPatient`,
        { patientId: patient_id },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.log("Error:", error.response?.data || error.message);
    }

    setShowOtp(true);
  };

  const handleSubmit = () => {
    try {
      axios.post(`${BASE_URL}/api/verifyPatientOtp`, {
        otp: otp,
        orderNo: oid,
      });
    } catch (error) {
      console.log(error);
    }
    setShowOtp(false);
  };
  //////////////otp/////////////
  return (
    <>
      {/* Page Header */}
      <div className="ml-4 font-bold text-xl font-display text-gray-700 underline decoration-gray-500">
        My Orders
      </div>

      <div className="shadow-sm grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-7 p-4 h-auto justify-items-center border rounded-xl bg-gray-100">
        {/* Heading */}
        <div className="col-span-1 lg:col-span-1 ml-6 underline text-gray-600 font-bold text-center lg:text-left mb-2 lg:mb-0">
          Delivery Analytics
        </div>

        {/* Statistics */}
        <div className="grid grid-cols-3 col-span-1 lg:col-span-1 gap-2 text-center mb-2 lg:mb-0">
          <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#8ADBCA] text-lg">
            <span className="font-semibold text-[#0E044A] underline">06</span>
            <span className="inline-block text-[#0E044A] font-medium text-[12px]">
              Total Orders
            </span>
          </div>
          <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#7CA7FC40] text-lg">
            <span className="font-semibold text-[#0E044A] underline">01</span>
            <span className="inline-block text-[#0E044A] font-medium text-[12px]">
              Delivered Orders
            </span>
          </div>
          <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#B34C6540] text-lg">
            <span className="font-semibold text-[#0E044A] underline">05</span>
            <span className="inline-block text-[#0E044A] font-medium text-[12px]">
              Pending Orders
            </span>
          </div>
        </div>

        {/* Date Picker */}
        <div className="col-span-1 lg:col-span-1 flex items-center justify-center lg:justify-end mt-4 lg:mt-0">
          <CalendarMonthIcon className="mr-2" />
          <DatePicker
            selected={selectedDate}
            // onChange={handleDateChange}
            className="rounded-md bg-white p-1 border border-gray-300"
            placeholderText="Select Date"
            dateFormat="yyyy-MM-dd" // Set display format to match "YYYY-MM-DD"
          />
        </div>
      </div>
      {/* Order List Section */}
      <div className="font-xl font-semibold underline font-display text-gray-800 mb-2 mt-5 sm:mb-0">
        Order List
      </div>

      {orders.map((order) => (
        <div className="shadow-md rounded-lg bg-white p-4 mb-4 mt-5">
          {/* Order Details */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center mb-4">
            <div className="flex items-center justify-center md:justify-start">
              <img
                src={`${BASE_URL}/api/image/${order.patient_details?.photo}`}
                alt="Patient"
                className="w-16 h-16 rounded-full"
              />
            </div>
            <div>
              <p className="font-semibold">
                Name: {order.patient_details?.patient_name}
              </p>
              {/* <p>Age: {patient.age}</p> */}
              <p>Sex: {order.patient_details?.gender}</p>
              <p>
                CPName: {order.cp_details.first_name}{" "}
                {order.cp_details.middle9_name} {order.cp_details.last_name}
              </p>
            </div>
            <div>
              <p>Order ID: {order.order_id}</p>
              {/*<p>Delivery ID: {order.deliveryId}</p>
              <p>Delivery: {order.deliveryDate}</p> */}
            </div>
            <div className="flex items-center">
              <DescriptionIcon />
              <label
                // htmlFor={`document-upload-${patient.id}`}
                className="ml-2 cursor-pointer text-blue-600 underline"
              >
                {/* {patient.document || "Upload"} */}
              </label>
            </div>
          </div>

          {/* Order Tracking Line */}
          <div className="mt-4 px-4 py-2 rounded-lg border shadow-sm bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <span className="font-bold text-sm">
                {/* Order Status: {patient.status} */}
              </span>
              <button className="text-blue-600 border rounded-md px-3 py-1 hover:bg-blue-50">
                Track Order
              </button>
            </div>

            {/* Tracking Line */}
            <div className="relative h-2 bg-gray-200 rounded-full mt-4">
              {/* Progress bar */}
              <div
                className={`absolute h-2 bg-blue-600 rounded-full ${
                  order.medicine_status === 5
                    ? "w-full"
                    : order.medicine_status === 4
                    ? "w-4/5"
                    : order.medicine_status === 3
                    ? "w-3/5"
                    : order.medicine_status === 2
                    ? "w-2/5"
                    : "w-1/5"
                }`}
              ></div>

              {/* 1 */}
              <div
                className={`absolute top-1/2 -translate-y-1/2 left-0 w-4 h-4 ${
                  order.medicine_status >= 1
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
              >
                ✓
              </div>

              {/*  2 */}
              <div
                className={`absolute top-1/2 -translate-y-1/2 left-[25%] w-4 h-4 ${
                  order.medicine_status >= 2
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
              >
                ✓
              </div>

              {/* 3 */}
              <div
                className={`absolute top-1/2 -translate-y-1/2 left-[50%] w-4 h-4 ${
                  order.medicine_status >= 3
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
              >
                ✓
              </div>

              {/* 4 */}
              <div
                className={`absolute top-1/2 -translate-y-1/2 left-[75%] w-4 h-4 ${
                  order.medicine_status >= 4
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
              >
                ✓
              </div>

              {/* 5 */}
              <button
                className={`absolute top-1/2 -translate-y-1/2 right-0 w-4 h-4 ${
                  order.medicine_status === 5
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
                onClick={() =>
                  handleOtp({
                    patient_id: order.patient_details?.patient_id,
                    orderId: order.order_id,
                  })
                }
              >
                ✓
              </button>
            </div>

            {/* Order Status Labels */}
            <div className="flex justify-between text-xs mt-2">
              <span>Order Placed</span>
              <span>Confirmed</span>
              <span>Dispatched</span>
              <span>Out for Delivery</span>
              <span>Delivered</span>
            </div>
          </div>
        </div>
      ))}
      {showOtp && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-lg font-bold text-gray-700">Enter OTP</h2>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="border rounded-md p-2 w-full mt-4"
              placeholder="Enter OTP"
            />
            <div className="flex justify-between mt-4">
              <button
                onClick={() => setShowOtp(false)}
                className="px-4 py-2 bg-gray-400 text-white rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-600 text-white rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyOrders;
