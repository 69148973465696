import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { BASE_URL } from "../../../utils/urlConfig";
import { useSelector } from "react-redux";
import Prescription from "../../NDH/NDHOrder/Prescription";

const PendingOreders = ({ handleInfo }) => {
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");

  const [pendingRequestsData, setPendingRequestsData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showprescription, setShowrescription] = useState(false);
  const [consultantid, setConsultantid] = useState();
  // fatch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/allMedicines`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        // Format the requested_date field
        const formattedData = res.data.medicineResults.map((medicine) => ({
          ...medicine,
          requested_date: medicine.requested_date.split("T")[0],
        }));

        console.log(formattedData);
        setPendingRequestsData(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [token]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleAccept = async () => {
    try {
      console.log("Selected IDs:", selectedIds);

      const requests = selectedIds.map(async (id) => {
        console.log("Updating status for order:", id);
        const response = await axios.put(
          `${BASE_URL}/api/updateMedicineStatus`,
          {
            orderNo: id,
            status: 2,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        console.log("Response data:", response.data);
        return response;
      });

      await Promise.all(requests);
      console.log("All requests completed successfully");

      // Refresh the data after update
      fetchData();
      setSelectedIds([]);
    } catch (err) {
      console.error("Error updating status:", err);
    }
  };
  const fetchData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/allMedicines`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const formattedData = res.data.medicineResults.map((medicine) => ({
        ...medicine,
        requested_date: medicine.requested_date.split("T")[0],
      }));

      console.log("Updated Data:", formattedData);
      setPendingRequestsData(formattedData);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handlprescription = (id) => {
    setShowrescription(true);
    setConsultantid(id);
  };

  return (
    <>
      <div className="mt-4 bg-white p-4 max-w-screen-xl mx-auto rounded-lg shadow-lg">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
            <div className=" sm:mr-auto">
              <span>
                <SearchIcon
                  className="relative left-9 "
                  style={{ fontSize: "30px", color: "gray" }}
                />
                <input
                  type="text"
                  placeholder="Search by order no"
                  className="p-1 pl-9 rounded-full 420px:w-[50vh] border-2 border-gray-400 320px:w-[40vh]"
                />
              </span>
            </div>
            <div className="flex ml-auto justify-center w-full sm:w-auto">
              <span
                className="h-8 font-semibold font-sans border px-4 py-1 rounded-lg bg-[#0E044A] text-white text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm"
                onClick={handleAccept}
              >
                <button>Accept</button>
              </span>
            </div>
          </div>

          <div className="overflow-x-auto">
            <div className="min-w-full">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 text-center">Order No</th>
                    <th className="px-4 py-2 text-center">Request Date</th>
                    <th className="px-4 py-2 text-center">Consultation ID</th>
                    <th className="px-4 py-2 text-center">Drug Requested</th>
                    <th className="px-4 py-2 text-center">Accept Req</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingRequestsData.map((row, index) => (
                    <tr key={index} className="border-b hover:bg-gray-50">
                      <td className="border p-2 text-center">
                        {row.medicine_order_id}
                      </td>
                      <td
                        className={`border p-2 text-center`}
                        onClick={handleInfo}
                      >
                        {row.requested_date}
                      </td>
                      <td className="border p-2 text-center">
                        {row.consultation_id}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <DescriptionIcon
                          className="mr-2 cursor-pointer"
                          onClick={() => handlprescription(row.consultation_id)}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          checked={selectedIds.includes(row.medicine_order_id)}
                          onChange={() =>
                            handleCheckboxChange(row.medicine_order_id)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} className="flex items-end mt-4">
        <Pagination
          // count={Math.ceil(filteredPatients.length / patientsPerPage)}
          // page={page}
          // onChange={handleChangePage}
          color="primary"
        />
      </Stack>

      {showprescription && (
        <div>
          <Prescription
            setShowrescription={setShowrescription}
            consultantid={consultantid}
          />
        </div>
      )}
    </>
  );
};

export default PendingOreders;
