// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import { getDatabase, ref, onValue, set, push, update, onDisconnect } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2Qof3Fq1JBJ_djgQNWbEbJ8GokJfBJrU",
  authDomain: "astha-fe024.firebaseapp.com",
  projectId: "astha-fe024",
  storageBucket: "astha-fe024.firebasestorage.app",
  messagingSenderId: "497792026121",
  appId: "1:497792026121:web:143e0f0cedd739d42a5ad8",
  measurementId: "G-GCYWJ1MBV6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db, ref, onValue, set, push, update, onDisconnect };
