import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { BASE_URL } from "../../../utils/urlConfig";
import { useSelector } from "react-redux";
import { collection, query, where, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase";
const localizer = momentLocalizer(moment);

function MyCalendar() {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showPopup, setShowPopup] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  // Handles slot selection in big calendar

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(moment(start).startOf("day").toDate()); // Set date without time
    setShowPopup(true);
  };

  /////////////////////////////department///////////////////////////////
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [cpDepartments, setCpDepartments] = useState([]);

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  useEffect(() => {
    const department = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/cpDepartments`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log(response.data.departments);
        setCpDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    department();
  }, [token]);
  //////////////////////////cps//////////////////////////////////////
  const [cps, setCps] = useState([]); // Store CPs list
  const [selectedCP, setSelectedCP] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/active-cps`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        setCps(response.data.data); // Store fetched CPs
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleCPChange = (e) => {
    setSelectedCP(e.target.value); // Store selected CP ID
  };

  ///////////////////////////////submit//////////////////////////////////

  const handleAddEvent = async () => {
    if (startTime && endTime && selectedDate && selectedCP) {
      const startDateTime = new Date(selectedDate);
      const endDateTime = new Date(selectedDate);

      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      startDateTime.setHours(startHour, startMinute);
      endDateTime.setHours(endHour, endMinute);

      const newEvent = {
        start: startDateTime,
        end: endDateTime,
        title: `${moment(startDateTime).format("hh:mm A")} - ${moment(
          endDateTime
        ).format("hh:mm A")}`,
      };

      console.log("Event Clicked");
      console.log(newEvent);

      // Update local events state
      setEvents([...events, newEvent]);

      // Format data for API submission
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      const formattedTime = `${moment(startDateTime).format("hh:mm A")}`;

      const availabilityPayload = {
        cpId: selectedCP,
        availability: [
          {
            date: formattedDate,
            times: [formattedTime], // Send selected time dynamically
          },
        ],
        department: selectedDepartment || "Cardiology",
      };

      console.log("Submitting availability:", availabilityPayload);

      try {
        const response = await axios.post(
          `${BASE_URL}/api/setCpAvailability`,
          availabilityPayload,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
        alert("Event added & availability submitted successfully!");
      } catch (error) {
        console.error("Error submitting availability:", error);
        alert("Failed to submit availability.");
      }

      // Reset input fields & close popup
      setShowPopup(false);
      setStartTime("");
      setEndTime("");
      setSelectedDate(null);
    } else {
      alert("Please fill all fields before adding an event.");
    }
  };

  /////////////////////firebase/////////////////////\
  const [availability, setAvailability] = useState([]);
  // const cp_id = "2";
  const cp_id = selectedCP;

  useEffect(() => {
    if (cp_id) {
      console.log("Fetching availability for CP ID:", cp_id);

      const q = query(
        collection(db, "cp_availability"),
        where("__name__", "==", cp_id)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("CP Availability:", newData);
        setAvailability(newData);
      });

      return () => unsubscribe();
    }
  }, [cp_id]);

  useEffect(() => {
    if (availability.length > 0) {
      const newEvents = availability.flatMap((item) =>
        item.availability.flatMap((slot) =>
          slot.times.map((time) => ({
            title: `Available at ${time}`,
            start: new Date(`${slot.date} ${time}`),
            end: new Date(`${slot.date} ${time}`),
            allDay: false,
          }))
        )
      );
      setEvents(newEvents);
    }
  }, [availability]);



  ////////////////////firebase//////////////////////

  return (
    <>
      <div className="flex justify-end">
        <span className="inline-block bg-blue-600 h-9 w-32 pt-1 rounded text-center text-white mb-1">
          {cps.length}
        </span>
      </div>

      <div className="shadow-lg bg-white rounded-lg p-4">
        {/* Select CP, Date Picker, and Department List */}
        <div className="flex flex-col sm:flex-row justify-between items-center mt-2 mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
          {/* Select CP Dropdown */}
          <div className="w-full sm:w-auto">
            <label className="mr-2">Select CP:</label>
            <select
              value={selectedCP}
              onChange={handleCPChange}
              className="border p-2 rounded w-full sm:w-auto"
            >
              <option value="">All</option>
              {cps.map((cp) => (
                <option key={cp.id} value={cp.id}>
                  {cp.first_name} {cp.last_name}
                </option>
              ))}
            </select>
          </div>

          {/* Small Date Picker Calendar */}
          {/* <div className="w-full sm:w-auto">
            <label className="mr-2">Select Date:</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="border p-2 rounded w-full sm:w-auto"
            />
          </div> */}

          {/* Department List Dropdown */}
          <div className="w-full sm:w-auto">
            <label className="mr-2">Department:</label>
            <select
              value={selectedDepartment}
              onChange={handleDepartmentChange}
              className="border p-2 rounded w-full sm:w-auto"
            >
              <option value="">Select Department</option>
              {cpDepartments.map((dept) => (
                <option key={dept.id} value={dept.name}>
                  {dept.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Responsive Big Calendar */}
        <div className="overflow-x-auto relative">
        <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, width: "100%" }}
        selectable
        onSelectSlot={handleSelectSlot} // Keeps working as before
      />

          {showPopup && (
            <div
              className="absolute top-20 left-20 bg-gray-300 p-4 rounded-md shadow-md flex flex-col z-10
        "
            >
              <h3>
                Select Time for {moment(selectedDate).format("MMMM D, YYYY")}
              </h3>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="mb-2 p-1 border border-gray-400"
              />
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="mb-2 p-1 border border-gray-400"
              />
              <button
                onClick={handleAddEvent}
                className="bg-blue-500 text-white p-2 rounded-md mb-2"
              >
                Add Event
              </button>
              <button
                onClick={() => setShowPopup(false)}
                className="bg-red-500 text-white p-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MyCalendar;
