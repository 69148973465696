import React, { useState, useEffect } from "react";
import avatar from "../images/Avtar (1).svg";
import fileIcon from "../images/Vector.png";
import CrossIcon from "@mui/icons-material/Close";
import Prescription from "./Prescription";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig"

const ConsultationCompleted = ({
  setShowConsultationCompleted,
  consultantid,
}) => {

  const [showprescription, setShowrescription] = useState(false);
  const [consultation, setConsultation] = useState();
  const [patient, setPatient] = useState([]);
  const [pic, setPic] = useState();
  const [age, setAge] = useState();
  const [audios, setAudio] = useState();

  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Consultations
        const res = await axios.get(
          `${BASE_URL}/api/consultations/${consultantid}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setConsultation(res.data.consultation);
  
        // Patient
        const resp = await axios.get(
          `${BASE_URL}/api/patient/${res.data.consultation.patient_details.id}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
  
        console.log(resp.data);
        setPatient(resp.data);
  
        setPic(
          resp.data.photo
            ? `${BASE_URL}/api/image/${
                resp.data.photo.startsWith("/")
                  ? resp.data.photo.slice(1)
                  : resp.data.photo
              }`
            : ""
        );
  
        const audioFile = res.data.consultation?.complaint_audio;

        if (audioFile) {
          const audioUrl = `${BASE_URL}/api/audio${audioFile.startsWith("/") ? audioFile : `/${audioFile}`}`;
          setAudio(audioUrl);
          console.log(audioUrl);
        } else {
          console.warn("No audio file available for this consultation.");
          setAudio(""); // Clear any existing audio URL to prevent accidental playback
        }
  
        // Calculate age
        const dob = new Date(resp.data.dob);
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
  
        const monthDifference = today.getMonth() - dob.getMonth();
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && today.getDate() < dob.getDate())
        ) {
          age--;
        }
  
        setAge(age);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  

  const [docWindow, setDocWindow] = useState(null);

  const handleViewAllDocuments = (documents) => {
    const prefix = `${BASE_URL}/api/pdf`;
    const docUrls = documents.split(",").map((url) => `${prefix}${url.trim()}`);
  
    let newWindow = docWindow;
    if (!newWindow || newWindow.closed) {
      newWindow = window.open("", "_blank", "width=800,height=600");
      newWindow.document.write(
        "<html><head><title>Consultation Documents</title></head><body></body></html>"
      );
      setDocWindow(newWindow);
    }
  
    docUrls.forEach((url) => {
      const iframe = newWindow.document.createElement("iframe");
      iframe.src = url;
      iframe.width = "100%";
      iframe.height = "500px";
      iframe.style.border = "none";
      newWindow.document.body.appendChild(iframe);
      newWindow.document.body.appendChild(document.createElement("br"));
    });
  };

  const playAudio = () => {
    if (!audios) {
      alert("No audio file available for playback.");
      return;
    }
  
    const audio = new Audio(audios);
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
      alert("Unable to play the audio file.");
    });
  };

  const handleprescription = () => {
    setShowrescription(true);
  };
  const handleclose = () => {
    setShowConsultationCompleted(false);
  };

  const handleOrder = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/orderByCfeclinic/${consultantid}`, {
      });
    
      console.log("Order placed successfully:", response.data);
    } catch (error) {
      console.error("Error placing order:", error.message);
    }
  };

  return (
    <>
      <div
        className={`block absolute top-20 mt-6 bg-gray-100 p-4 md:p-6 min-h-[80vh] x-3/5 xl:w-3/4 overflow-y-auto rounded-lg border-black w-screen md:w-auto
                   ${showprescription ? "hidden " : ""} 
 `}
      >
        <div className="text-xl font-bold underline mb-5">
          Consultation Completed
        </div>
        <div className="flex flex-col xl:flex-row bg-[#D1D5DB] p-2 mt-10 rounded-lg items-center content-center">
          <div className="flex justify-center items-center md:justify-start  h-[120px] xl:w-[150px]">
            <img
              src={pic || avatar}
              alt="Avatar"
              className="contrast-200 h-20 w-20 sm:ml-5 lg:mx-auto md:mx-auto lg:px-1 "
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className=" text-center flex-col lg:flex-row flex justify-between  flex-wrap xl:w-[500px]">
              <div>
                <span className="font-semibold">Name:</span>
                <span className="ml-1">{patient.name}</span>
              </div>
              <div className="text-nowrap">
                <span className="font-semibold">Age:</span>
                <span className="ml-1">{age}</span>
              </div>
              <div className="text-nowrap md:text-wrap">
                <span className="font-semibold">sex:</span>
                <span className="ml-1">{patient.gender}</span>
              </div>
              <div className="text-nowrap md:text-wrap">
                <span className="font-semibold">Total Consultations:</span>
                <span className="ml-1">{patient.total_consultations}</span>
              </div>
            </div>
            <div className=" text-center flex-col lg:flex-row flex justify-between  flex-wrap lg:w-[400px]">
              <div>
                <span className="font-semibold">Mobile No:</span>
                <span className="ml-1">{patient.mobile}</span>
              </div>
              <div>
                <span className="font-semibold">ID:</span>

                <span className="font-semibold">{patient.id}</span>
              </div>
              <div>
                <span className="font-semibold">Delivery:</span>
                <span className="ml-1">M/F</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 h-[200px]">
            <div className="text-l font-bold underline mt-[30px]">
              Chief Complaint
            </div>
            <p>{consultation?.chief_complaint || "No data available"}</p>
          </div>
          <div className="flex md:w-1/2 h-[300px] justify-center items-center gap-[60px]">
            <div className="flex flex-col">
              <div className="border-2 border-dotted rounded border-blue-900 h-20 w-[100px] mt-2 flex flex-col justify-center items-center">
                {/* <DescriptionIcon /> */}
                <img src={fileIcon} className="mt-2" alt="" />

                <label
                  htmlFor="proof-document"
                  className="text-center cursor-pointer text-[#0E044A] underline font-semibold mt-2"
                >
                  PDF Document
                </label>
              </div>
              <button
                className="bg-[#0E044A] text-white rounded max-w-20 mt-2 py-1 mx-2"
                type="button"
                onClick={() => handleViewAllDocuments(consultation.documents)}
              >
                View file
              </button>
            </div>

            <div className="flex flex-col">
              <div className="border-2 border-dotted rounded border-blue-900 h-20 w-[100px] mt-2 flex flex-col justify-center items-center">
                {/* <DescriptionIcon className="text-[40px] with-" /> */}
                <img src={fileIcon} className="mt-2" alt="" />
                <label
                  htmlFor="proof-document"
                  className="text-center cursor-pointer text-[#0E044A] underline font-semibold mt-2"
                >
                  Audio Document
                </label>
              </div>
              <button
                className="bg-[#0E044A] text-white rounded max-w-20 mt-2 py-1 mx-2"
                onClick={playAudio}
                type="button"
              >
                View file
              </button>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col gap-4">
          <div>
            <p className="text-[15px] text-[#0E044A] underline font-bold">
              Next Consultation at 02/02/2035
            </p>
            <button
              // onClick={handleSubmit}
              className=" p-2 rounded-lg  border-2 border-[#0E044A] text-[#0E044A] w-[250px] font-"
              onClick={handleprescription}
            >
              View prescription
            </button>
          </div>
          <div>
            <p className="text-[15px] text-[#0E044A] underline font-bold">
              The estimated medicine cost is ₹ 325.00
            </p>
            <button
              onClick={handleOrder}
              className="border p-2 rounded-lg bg-[#0E044A] text-white h-[45px] w-[250px]"
            >
              Order medicine
            </button>
          </div>
        </div>
        <CrossIcon
          className="absolute top-8 right-5 cursor-pointer"
          onClick={handleclose}
        />
      </div>

      {showprescription && (
        <div>
          <Prescription setShowrescription={setShowrescription} patient={patient} consultantid={consultantid}  />
        </div>
      )}
    </>
  );
};

export default ConsultationCompleted;
