import React, { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import CrossIcon from "@mui/icons-material/Close";
import Prescription from "../../NDH/NDHOrder/Prescription";

const OrderStatus = ({ setShowstatus, status, cid, position }) => {
  const handeClose = () => {
    setShowstatus(false);
  };
  const [showprescription, setShowrescription] = useState(false);
  const [consultantid, setConsultantid] = useState();

  const handleprescrition = () => {
    setShowrescription(true);
    setConsultantid(cid);
  };

  return (
    <>
      <div
        className="absolute w-[300px] bg-gray-200 shadow-lg border p-4 rounded-lg flex"
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
        }}
      >
        <div
          className="absolute top-[3px] bg-gray-100 shadow-lg right-[-5px] w-0 h-0 border-t-[10px] border-r-[10px] border-transparent border-t-gray-200 border-r-gray-200"
          style={{
            transform: "rotate(45deg)",
            zIndex: 10, 
          }}
        ></div>

        <CrossIcon
          className="absolute top-1 right-1 cursor-pointer"
          onClick={handeClose}
        />
        <div>
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-bold text-gray-900">Time Line</h2>
          </div>

          <div className="flex mt-5">
            {/* Vertical Tracking Line */}
            <div className="relative h-36 w-1 bg-gray-200 rounded-full mt-0">
              <div
                className={`absolute w-2 bg-blue-600 rounded-full ${
                  status === 4 ? "h-full" : status === 3 ? "h-1/2" : "h-0"
                }`}
                style={{ top: 0 }}
              ></div>

              {/* Checkpoints */}
              <div
                className={`absolute left-1/2 -translate-x-1/2 w-4 h-4 ${
                  status >= 2
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
                style={{ top: "0%" }}
              >
                {status >= 2 ? "\u2713" : "X"}
              </div>
              <div
                className={`absolute left-1/2 -translate-x-1/2 w-4 h-4 ${
                  status >= 3
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
                style={{ top: "50%" }}
              >
                {status >= 3 ? "\u2713" : "X"}
              </div>
              <div
                className={`absolute left-1/2 -translate-x-1/2 w-4 h-4 ${
                  status === 4
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
                style={{ top: "100%" }}
              >
                {status === 4 ? "\u2713" : "X"}
              </div>
            </div>

            {/* Order Status Labels */}
            <div className="flex flex-col  text-xs ml-4">
              <div className="flex items-center mb-[56px]">
                <span
                  className={`ml-3 ${
                    status >= 2 ? "text-blue-600" : "text-gray-400"
                  }`}
                >
                  Order Placed
                </span>
              </div>
              <div className="flex items-center mb-[56px]">
                <span
                  className={`ml-3 ${
                    status >= 3 ? "text-blue-600" : "text-gray-400"
                  }`}
                >
                  Dispatched
                </span>
              </div>
              <div className="flex items-center">
                <span
                  className={`ml-3 ${
                    status === 4 ? "text-blue-600" : "text-gray-400"
                  }`}
                >
                  Delivered
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="ml-10">
          <div className="mb-8">
            <h2 className="text-xl font-bold">Payment</h2>
            <div className="text-sm mt-5">
              <p className="flex justify-between">
                <span>Subtotal:</span>
                <span>₹</span>
              </p>
              <p className="flex justify-between">
                <span>Tax (22%):</span>
                <span>-₹</span>
              </p>
              <p className="flex justify-between font-semibold">
                <span>Total:</span>
                <span>₹</span>
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Prescription</h2>
            <div className="w-12 h-12 flex items-center justify-center border-2 border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 mt-5">
              <DescriptionIcon onClick={handleprescrition} />
            </div>
          </div>
        </div>
      </div>
      {showprescription && (
        <div>
          <Prescription
            setShowrescription={setShowrescription}
            consultantid={consultantid}
          />
        </div>
      )}
    </>
  );
};

export default OrderStatus;
