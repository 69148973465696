import React from "react";
import Typography from "@mui/material/Typography";
import Chart from "../images/Consultation Graph.svg";
import axios from "axios"
import { BASE_URL } from "../../../utils/urlConfig";
import { useSelector } from "react-redux";

const CpDashboard = () => {

  const token=useSelector((state) => state.auth.token) || localStorage.getItem("token");


  const handleStatus=()=>{
    try {
      axios.patch(`${BASE_URL}/apitoggle-status/cp`,{
        headers: {
          Authorization: `${token}`,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div>
        <div className="p-4">
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#454545",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              textDecoration: "underline",
              textDecorationColor: "#454534",
              textDecorationThickness: "1px",
            }}
          >
            Dashboard
          </Typography>

          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 h-full`}
          >
            <div className="shadow-xl h-40 bg-[#508BCA91] border border-b-gray-400 rounded-lg p-4 col-span-1 relative">
              {/* ............................... */}
              <p className="text-white font-medium">Scheduled Consultation</p>
              <p className="absolute text-lg bottom-3 right-3 font-medium text-white">20</p>
            </div>

            <div className="shadow-xl h-40 bg-[#365268] border border-b-gray-400 rounded-lg col-span-1 p-4 relative">
              {/* ............................ */}
              <p className="text-white font-medium">Instant Consultation</p>
              <p className="absolute text-lg bottom-3 right-3 font-medium text-white">20</p>
            </div>

            <div className="shadow-xl h-40 bg-[#508BCA91] border border-b-gray-400 rounded-lg p-4 col-span-1 relative">
              {/* ....................... */}
              <p className="text-white font-medium">Total Consultation</p>
              <p className="absolute text-lg bottom-3 right-3 font-medium text-white">20</p>
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
          <img src={Chart} alt="" className="w-full h-auto" />
        </div>
      </div>
    </>
  );
};

export default CpDashboard;
