import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../utils/urlConfig";
import CrossIcon from "@mui/icons-material/Close";

const Medicine = ({ setShowMD }) => {
  const [medicines, setMedicines] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/drug-details`);
        setMedicines(res.data.drugDetails);
      } catch (err) {
        console.error("Error fetching medicine data:", err);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setShowMD(false);
  };

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-2xl absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <CrossIcon
          className="absolute top-2 right-2 cursor-pointer"
          onClick={handleClose}
        />
        <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
          Medicine Details
        </h2>
        <div className="overflow-x-auto mt-5">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-center">
                <th className="border border-gray-300 p-2">Sl. No.</th>
                <th className="border border-gray-300 p-2">Name</th>
                <th className="border border-gray-300 p-2">Price</th>
                <th className="border border-gray-300 p-2">Composition</th>
              </tr>
            </thead>
            <tbody>
              {medicines.map((medicine, index) => (
                <tr key={medicine.id} className="hover:bg-gray-100 text-center">
                  <td className="border border-gray-300 p-2">{index + 1}</td>
                  <td className="border border-gray-300 p-2">{medicine.name}</td>
                  <td className="border border-gray-300 p-2">₹{medicine.price}</td>
                  <td className="border border-gray-300 p-2">{medicine.composition}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Medicine;
