import React from 'react'

const ConsultationDetailsCard = () => {
  return (
    <div>
      <div className="p-6 bg-white rounded-lg shadow-md">
  {/* Header Section */}
  <div className="flex items-center mb-4">
    <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
      <img src="path/to/avatar.png" alt="Avatar" className="w-full h-full rounded-full" />
    </div>
    <div className="ml-4">
      <h2 className="text-xl font-bold">Name: Full Name</h2>
      <p>#ID0002</p>
    </div>
  </div>

  {/* Patient Details */}
  <div className="grid grid-cols-2 gap-4 mb-4">
    <div>
      <p><strong>ID:</strong> ID0002</p>
      <p><strong>Age:</strong> 100</p>
      <p><strong>CP Name:</strong> Full Name of CP</p>
      <p><strong>CF Name:</strong> Full Name of CF</p>
    </div>
    <div>
      <p><strong>Time:</strong> 02:00</p>
      <p><strong>Sex:</strong> Female</p>
    </div>
  </div>

  {/* Chief Complaint */}
  <div className="mb-4">
    <h3 className="text-lg font-semibold">Chief Complaint</h3>
    <p className="text-gray-600">
      This is a very long random text describing the condition of the patient. All the additional data regarding this patient will be noted down here prior to the call.
    </p>
  </div>

  {/* Medicines Section */}
  <div className="mb-4">
    <h3 className="text-lg font-semibold">Medicine</h3>
    <ul className="list-disc list-inside text-gray-600">
      <li>Document Name.doctype</li>
      <li>Document Name.doctype</li>
      <li>Document Name.doctype</li>
    </ul>
  </div>

  {/* Consultation Records */}
  <div className="mb-4">
    <h3 className="text-lg font-semibold">Consultation Records</h3>
    <ul className="list-disc list-inside text-gray-600">
      <li>Document Name.doctype</li>
      <li>Document Name.doctype</li>
      <li>Document Name.doctype</li>
    </ul>
  </div>

  {/* Observations */}
  <div className="mb-4">
    <h3 className="text-lg font-semibold">Observation</h3>
    <p className="text-gray-600">
      This is a very long random text describing the condition of the patient. All the additional data regarding this patient will be noted down here prior to the call.
    </p>
  </div>

  {/* Footer Buttons */}
  <div className="flex justify-between">
    <button
      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      onClick={() => console.log("Back clicked")}
    >
      Back
    </button>
    <button
      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      onClick={() => console.log("View Prescription clicked")}
    >
      View Prescription
    </button>
  </div>
</div>

    </div>
  )
}

export default ConsultationDetailsCard

